<div fxLayoutAlign="start start" fxLayout="column" fxLayoutGap="16px" class="yosync-profile">
    <form [formGroup]="identityForm" fxFlex="100" fxLayout="column" fxLayoutGap="10px" fxFill>
        <div formArrayName="mobile" fxFlex="100" fxLayout="column" fxLayoutGap="10px">
            <div class="yosync-profile-row">
                <div class="border-bottom p-0" *ngFor="let identityMobile of identityMobileArray?.controls let mId = index">
                    <div [formGroupName]="mId">
                        <div fxLayout="row" class="border-bottom no-padding" fxLayoutAlign="start center">
                            <div fxLayout="row" class="border-right" fxLayoutAlign="start center" fxLayoutGap="3px" fxFlex="40">
                                <div class="lock cp" *ngIf="!identityMobileArray.controls[mId].value.is_primary" (click)="removeMobile(mId)"><img src="../../../../assets/images/minus.png"></div>
                                <div fxLayout="row" *ngIf="!identityMobileArray.controls[mId].value.is_primary" class="cp" fxLayoutAlign="start center" fxLayoutGap="3px" [matMenuTriggerFor]="menu" #menuTrigger>
                                    <div class="mobile cp">
                                        <div class="input" *ngIf="identityMobileArray.controls[mId]['controls'].label.value !== 'OTHER'">
                                            <input class="input-height" type="hidden" formControlName="label" [readonly]="true">
                                            <input class="input-height p-0" formControlName="name_of_label" [readonly]="true">
                                        </div>
                                        <div class="input" *ngIf="identityMobileArray.controls[mId]['controls'].label.value === 'OTHER'">
                                            <input class="input-height p-0" *ngIf="identityMobileArray.controls[mId]['controls'].label_name.value" formControlName="label_name" [readonly]="true">
                                            <input class="input-height p-0" *ngIf="!identityMobileArray.controls[mId]['controls'].label_name.value" formControlName="name_of_label" [readonly]="true">
                                        </div>

                                    </div>
                                    <div class="lock"><img src="../../../../assets/images/arrow-right.png"></div>
                                    <mat-menu #menu="matMenu">
                                        <div *ngFor="let label of availableLabel">
                                            <!-- <button mat-menu-item (click)="selectMobileLabel(label.label_code,mId)">{{label.label_name}}</button> -->
                                            <button mat-menu-item (click)="selectMobileLabel(label.label_code, label.label_name, mId)">{{label.label_name}}</button>
                                        </div>

                                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

                                            <div class="input label">
                                                <input placeholder="Custom label" formControlName="label_name" (click)="$event.stopPropagation();" />
                                            </div>
                                            <div class="input" fxLayoutAlign="space-between center" *ngIf="identityMobileArray.controls[mId]['controls'].label_name.value">
                                                <!-- <button class="button-save" (click)="selectMobileLabel('OTHER',mId)">Add</button> -->
                                                <button class="button-save add" (click)="selectMobileLabel('OTHER', identityMobileArray.controls[mId].value.label_name, mId)">Add</button>
                                            </div>
                                        </div>


                                    </mat-menu>
                                </div>
                                <div fxLayout="row" class="cp" *ngIf="identityMobileArray.controls[mId].value.is_primary" fxLayoutAlign="start center" fxLayoutGap="3px">
                                    <div class="mobile cp">
                                        <div class="input" *ngIf="identityMobileArray.controls[mId]['controls'].label.value !== 'OTHER'">
                                            <input class="input-height" type="hidden" formControlName="label" [readonly]="true">
                                            <input class="input-height p-0" formControlName="name_of_label" [readonly]="true">
                                        </div>
                                        <div class="input" *ngIf="identityMobileArray.controls[mId]['controls'].label.value === 'OTHER'">
                                            <input class="input-height" formControlName="label_name" [readonly]="true">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class=" kannada ml-10" fxLayout="column" fxFlex="47" fxLayoutAlign="space-between center">
                                <div fxLayout="row" fxFlex="100" class="w-100" fxLayoutGap="10px">
                                    <div fxFlex="20" fxLayout="column" class="input text-right">
                                        <input class="text-right input-height p-0" placeholder="+91" formControlName="country_code" [readonly]="identityMobileArray.controls[mId]['controls'].is_primary.value" (blur)="createMobilePayload(mId,0)">
                                    </div>
                                    <div fxFlex="80" fxLayout="column" class="input">
                                        <input class="input-height p-0" placeholder="98765 43210" formControlName="phone" [readonly]="identityMobileArray.controls[mId]['controls'].is_primary.value" [maxlength]="10" (blur)="createMobilePayload(mId,0)">
                                    </div>
                                </div>
                            </div>

                            <div class=" kannada ml-10" *ngIf="identityMobileArray.controls[mId].value.is_primary" fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="primary-btn">P</div>
                            </div>
                            <div class="kannada ml-10" *ngIf="(identityMobileArray.controls[mId].value.contact_id && !identityMobileArray.controls[mId].value.is_verified) && (identityMobileArray.controls[mId]['controls'].phone.valid && identityMobileArray.controls[mId]['controls'].country_code.valid)"
                                fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="verify-btn" (click)="sendVerification(identityMobileArray.controls[mId].value, mId)">
                                    Verify</div>
                            </div>
                            <div class="py-8 kannada ml-10" *ngIf="!identityMobileArray.controls[mId].value.contact_id && identityMobileArray.controls[mId].value.temp_id && !identityMobileArray.controls[mId].value.is_verified" fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="verify-btn" (click)="createMobilePayload(mId,1)">Verify</div>
                            </div>
                            <div class="kannada ml-10" *ngIf="(identityMobileArray.controls[mId].value.is_verified && !identityMobileArray.controls[mId].value.is_primary) && (identityMobileArray.controls[mId]['controls'].phone.valid && identityMobileArray.controls[mId]['controls'].country_code.valid)"
                                fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="set-primary-btn" (click)="setPrimaryDialog(identityMobileArray.controls[mId].value, mId)">
                                    P</div>
                            </div>
                            <div class=" kannada ml-10" *ngIf="(identityMobileArray.controls[mId]['controls'].country_code.touched && identityMobileArray.controls[mId]['controls'].country_code.errors?.required) || (identityMobileArray.controls[mId]['controls'].country_code.touched && identityMobileArray.controls[mId]['controls'].country_code.errors?.pattern) || (identityMobileArray.controls[mId]['controls'].phone.touched && identityMobileArray.controls[mId]['controls'].phone.errors?.required) || (identityMobileArray.controls[mId]['controls'].phone.touched && identityMobileArray.controls[mId]['controls'].phone.errors?.pattern)"
                                fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="error-tooltip-container" mat-raised-button matTooltip="Enter a valid mobile number" aria-label="Tooltip will display when focused or hovered over">
                                    !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" class="border-bottom cp" fxLayoutAlign="space-between center">
                    <div fxLayout="row" class="py-8" fxLayoutAlign="start center" fxLayoutGap="3px" fxFlex="auto" (click)="addMoreMobile()">
                        <div class="lock"><img src="../../../../assets/images/plus.png"></div>
                        <div class="language p-5">add phone</div>
                    </div>
                </div>
            </div>
        </div>
        <div formArrayName="email" fxFlex="100" fxLayout="column" fxLayoutGap="10px">
            <div class="yosync-profile-row">
                <div class="border-bottom no-padding" *ngFor="let identityEmail of identityEmailArray?.controls; let eId = index;">
                    <div [formGroupName]="eId">
                        <div fxLayout="row" class="border-bottom no-padding" fxLayoutAlign="start center">
                            <div fxLayout="row" class="border-right " fxLayoutAlign="start center" fxLayoutGap="3px" fxFlex="40">
                                <div class="lock" *ngIf="!identityEmailArray.controls[eId].value.is_primary" (click)="removeEmail(eId)">
                                    <img src="../../../../assets/images/minus.png">
                                </div>
                                <div fxLayout="row" class="cp" *ngIf="!identityEmailArray.controls[eId].value.is_primary" fxLayoutAlign="start center" fxLayoutGap="3px" [matMenuTriggerFor]="emailMenu" #menuTrigger>
                                    <div class="language">
                                        <div class="input" *ngIf="identityEmailArray.controls[eId]['controls'].label.value !== 'OTHER'">
                                            <input class="input-height p-0" type="hidden" formControlName="label" [readonly]="true">
                                            <input class="input-height p-0" formControlName="name_of_label" [readonly]="true">
                                        </div>
                                        <div class="input" *ngIf="identityEmailArray.controls[eId]['controls'].label.value === 'OTHER'">
                                            <input class="p-0" *ngIf="identityEmailArray.controls[eId]['controls'].label_name.value" formControlName="label_name" [readonly]="true">
                                            <input class="p-0" *ngIf="!identityEmailArray.controls[eId]['controls'].label_name.value" formControlName="name_of_label" [readonly]="true">
                                        </div>
                                    </div>

                                    <div class="lock"><img src="../../../../assets/images/arrow-right.png"></div>
                                    <mat-menu #emailMenu="matMenu">
                                        <div *ngFor="let label of availableLabel">
                                            <button mat-menu-item (click)="selectEmailLabel(label.label_code, label.label_name, eId)">{{label.label_name}}</button>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                                            <div class="input label">
                                                <input class="input-height" placeholder="Custom label" formControlName="label_name" (click)="$event.stopPropagation();" />
                                            </div>
                                            <div class="input" fxLayoutAlign="space-between center" *ngIf="identityEmailArray.controls[eId]['controls'].label_name.value">
                                                <button class="button-save add" (click)="selectEmailLabel('OTHER', identityEmailArray.controls[eId].value.label_name, eId)">Add</button>
                                            </div>
                                        </div>
                                    </mat-menu>
                                </div>

                                <div fxLayout="row" class="cp" *ngIf="identityEmailArray.controls[eId].value.is_primary" fxLayoutAlign="start center" fxLayoutGap="3px">
                                    <div class="language">
                                        <div class="input" *ngIf="identityEmailArray.controls[eId]['controls'].label.value !== 'OTHER'">
                                            <input class="input-height p-0" type="hidden" formControlName="label" [readonly]="true">
                                            <input class="input-height p-0" formControlName="name_of_label" [readonly]="true">
                                        </div>
                                        <div class="input" *ngIf="identityEmailArray.controls[eId]['controls'].label.value === 'OTHER'">
                                            <input class="input-height" formControlName="label_name" [readonly]="true">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" kannada ml-10" fxLayout="row" fxFlex="47" fxLayoutAlign="space-between center">
                                <div class="input">
                                    <input class="input-height p-0" placeholder="user@yosync.com" formControlName="email" [readonly]="identityEmailArray.controls[eId]['controls'].is_primary.value" (blur)="createEmailPayload(eId,0)">
                                </div>
                            </div>
                            <div class=" kannada ml-10" *ngIf="identityEmailArray.controls[eId].value.is_primary" fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="primary-btn">P</div>
                            </div>
                            <div class="kannada" *ngIf="(identityEmailArray.controls[eId].value.contact_id && !identityEmailArray.controls[eId].value.is_verified) && (identityEmailArray.controls[eId]['controls'].email.valid)" fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="verify-btn " (click)="sendVerification(identityEmailArray.controls[eId].value, eId)">
                                    Verify
                                </div>
                            </div>
                            <div class="py-8 kannada ml-10" *ngIf="!identityEmailArray.controls[eId].value.contact_id && identityEmailArray.controls[eId].value.temp_id && !identityEmailArray.controls[eId].value.is_verified" fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="verify-btn" (click)="createEmailPayload(eId,1)">Verify</div>
                            </div>
                            <div class="kannada ml-10" *ngIf="(identityEmailArray.controls[eId].value.is_verified && !identityEmailArray.controls[eId].value.is_primary) && (identityEmailArray.controls[eId]['controls'].email.valid)" fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="set-primary-btn" (click)="setPrimaryDialog(identityEmailArray.controls[eId].value, eId)">P
                                </div>
                            </div>
                            <div class=" kannada ml-10" *ngIf="(identityEmailArray.controls[eId]['controls'].email.touched && identityEmailArray.controls[eId]['controls'].email.errors?.required) || (identityEmailArray.controls[eId]['controls'].email.touched && identityEmailArray.controls[eId]['controls'].email.errors?.pattern)"
                                fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                <div class="error-tooltip-container" mat-raised-button matTooltip="Enter a valid email" aria-label="Tooltip will display when focused or hovered over">
                                    !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" class="border-bottom cp" fxLayoutAlign="space-between center">
                    <div fxLayout="row" class="py-8" fxLayoutAlign="start center" fxLayoutGap="3px" fxFlex="auto" (click)="addMoreEmail()">
                        <div class="lock"><img src="../../../../assets/images/plus.png"></div>
                        <div class="language">add email</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="yosync-profile-row py-0">
            <div fxLayout="row" class="border-bottom no-padding" fxLayoutAlign="space-between center">
                <div fxLayout="row" class="border-right " fxLayoutAlign="start center" fxLayoutGap="3px" fxFlex="40">
                    <div class="lock">
                        <!-- <img src="../../../../assets/images/minus.png"> -->
                    </div>
                    <div fxLayout="row" class="cp" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <div class="language">Website </div>
                        <div class="lock">
                            <!-- <img src="../../../../assets/images/arrow-right.png"> -->
                        </div>
                    </div>
                </div>
                <div class=" kannada ml-10" fxLayout="row" fxFlex="60" fxLayoutAlign="space-between center">
                    <div class="input">
                        <input class="" placeholder="www.yosync.com" formControlName="link">
                    </div>
                </div>
                <div class="py-8 kannada ml-10" *ngIf="identityForm.controls.link.invalid" fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                    <div class="error-tooltip-container" mat-raised-button matTooltip="The link is invalid" aria-label="Tooltip will display when focused or hovered over">
                        !
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- website close -->
    <div class="yosync-profile-row w-100">
        <div fxLayout="row wrap" class="border-bottom no-padding" fxLayoutAlign="space-between center" fxFlex="auto" fxLayoutGap="1%">
            <div fxLayout="row" class=" social-box no-bg" fxLayoutAlign="start center" fxFlex="31%">
                <div class="lock social" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px" fxFlex="100">
                    Social Media
                </div>
            </div>
            <div fxLayout="row" class=" social-box" fxLayoutAlign="start center" fxFlex="31%" (click)="addSocialMedia('KOO')">
                <div class="lock social" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px" fxFlex="100">
                    <div class="indicator" fxLayout="column" fxLayoutAlign="center end  " fxFlex="auto">
                        <img src="../../../../assets/images/green-tick.svg" *ngIf="getsocialMediaLinks('KOO')">
                        <img src="../../../../assets/images/yellow-tick.svg" *ngIf="!getsocialMediaLinks('KOO')">
                    </div>
                    <img src="../../../../assets/images/social/koo.png">
                    <span> Koo</span>
                </div>
            </div>
            <div fxLayout="row" class=" social-box" fxLayoutAlign="start center" fxFlex="31%" (click)="addSocialMedia('TWITTER')">
                <div class="lock social" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px" fxFlex="100">
                    <div class="indicator" fxLayout="column" fxLayoutAlign="center end  " fxFlex="auto">
                        <img src="../../../../assets/images/green-tick.svg" *ngIf="getsocialMediaLinks('TWITTER')">
                        <img src="../../../../assets/images/yellow-tick.svg" *ngIf="!getsocialMediaLinks('TWITTER')">
                    </div>
                    <img src="../../../../assets/images/social/twitter.png">
                    <span> Twitter</span>
                </div>
            </div>
            <div fxLayout="row" class=" social-box" fxLayoutAlign="start center" fxFlex="31%" (click)="addSocialMedia('FACEBOOK')">
                <div class="lock social" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px" fxFlex="100">
                    <div class="indicator" fxLayout="column" fxLayoutAlign="center end  " fxFlex="auto">
                        <img src="../../../../assets/images/green-tick.svg" *ngIf="getsocialMediaLinks('FACEBOOK')">
                        <img src="../../../../assets/images/yellow-tick.svg" *ngIf="!getsocialMediaLinks('FACEBOOK')">
                    </div>
                    <img src="../../../../assets/images/social/fb.png">
                    <span> Facebook</span>
                </div>
            </div>
            <div fxLayout="row" class=" social-box" fxLayoutAlign="start center" fxFlex="31%" (click)="addSocialMedia('INSTAGRAM')">
                <div class="lock social" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px" fxFlex="100">
                    <div class="indicator" fxLayout="column" fxLayoutAlign="center end " fxFlex="auto">
                        <img src="../../../../assets/images/green-tick.svg" *ngIf="getsocialMediaLinks('INSTAGRAM')">
                        <img src="../../../../assets/images/yellow-tick.svg" *ngIf="!getsocialMediaLinks('INSTAGRAM')">
                    </div>
                    <img src="../../../../assets/images/social/insta.png">
                    <span> Instagram</span>
                </div>
            </div>
            <div fxLayout="row" class=" social-box" fxLayoutAlign="start center" fxFlex="31%" (click)="addSocialMedia('LINKEDIN')">
                <div class="lock social" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px" fxFlex="100">

                    <div class="indicator" fxLayout="column" fxLayoutAlign="center end  " fxFlex="auto">
                        <img src="../../../../assets/images/green-tick.svg" *ngIf="getsocialMediaLinks('LINKEDIN')">
                        <img src="../../../../assets/images/yellow-tick.svg" *ngIf="!getsocialMediaLinks('LINKEDIN')">
                    </div>
                    <img src="../../../../assets/images/social/linked-in.png">
                    <span> Linkedin</span>
                </div>
            </div>
        </div>
    </div>
    <div class="yosync-login-footer" fxLayout="column" fxFlex="100" fxLayoutAlign="start center">
        <button class="button reset" (click)="updateContact()">Update</button>
    </div>
</div>

<ng-template #deleteDialog let-data>
    <div class="yosync-delete-action-dialog">
        <mat-dialog-content>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
                <div fxFlex="auto" class="text-center mt-1" fxFlexFill>
                    <div class="dh-delete-action-dialog--content text-center">
                        <p class="content--description mt-2">
                            Are you sure you want to delete?
                        </p>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <div class="yosync-delete-action-dialog--action yosync-login-footer mt-3">
                <button class="yosync-btn solid btn-color button create" matDialogClose (click)="closeDeleteConfirmation(data.contactInfo)">
          Yes
        </button> &nbsp;&nbsp;
                <button class="yosync-btn outline btn-color button create" matDialogClose (click)="closeDeleteConfirmation(null)">
          No
        </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>

<ng-template #networkDialog let-data>
    <div class="yosync-delete-action-dialog">
        <mat-dialog-content>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
                <div fxFlex="auto" class="text-center mt-1" fxFlexFill>
                    <div class="dh-delete-action-dialog--content text-center">
                        <div class="yosync-login input new" fxFlex="auto">
                            <input type="text" [(ngModel)]="socialMediaLink" autocomplete="off" placeholder="URL" />
                            <div class="msg-error text-left ml-1">{{socialMediaError}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <div class="yosync-delete-action-dialog--action yosync-login-footer mt-2">
                <button class="yosync-btn solid btn-color button create" (click)="createNetwork(data.networkType)">
          Ok
        </button> &nbsp;&nbsp;
                <button class="yosync-btn outline btn-color button create" matDialogClose (click)="createNetwork(null)">
          Cancel
        </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>

<ng-template #verificationDialog let-data>
    <div class="yosync-delete-action-dialog">
        <mat-dialog-content>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
                <div fxFlex="auto" class="text-center mt-1" fxFlexFill>
                    <div class="dh-delete-action-dialog--content text-center">
                        <div class="yosync-login input new" fxFlex="auto">
                            <p class="msg-to-end-user text-left">{{ (data.contactInfo.type == 'PHONE') ? 'Verify your Number' : 'Verify your Email' }}</p>
                            <input type="text" [(ngModel)]="otp" placeholder="Please enter OTP" autocomplete="off" />
                            <div class="msg-error text-left">{{ verifyOtperror }}</div>

                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <div class="yosync-delete-action-dialog--action yosync-login-footer mt-1">
                <button class="yosync-btn button create solid btn-color" (click)="verifyContact(data.contactInfo, data.index)">
          Ok
        </button> &nbsp;&nbsp;
                <button class="yosync-btn button create outline btn-color" matDialogClose>
          Cancel
        </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>

<ng-template #primaryDialog let-data>
    <div class="yosync-delete-action-dialog">
        <mat-dialog-content>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
                <div fxFlex="auto" class="text-center mt-1" fxFlexFill>
                    <div class="dh-delete-action-dialog--content text-center">
                        <p class="content--description mt-2">
                            Are you sure you want to set as primary?
                        </p>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <div class="yosync-delete-action-dialog--action yosync-login-footer">
                <button class="yosync-btn solid btn-color button create" matDialogClose (click)="setPrimary(data.contactInfo, data.index)">
          Ok
        </button> &nbsp;&nbsp;
                <button class="yosync-btn outline btn-color button create" matDialogClose>
          Cancel
        </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>

<div class="yosync-delete-action-dialog">
    <mat-dialog-content>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
            <div fxFlex="auto" class="text-center mt-1" fxFlexFill>
                <div class="dh-delete-action-dialog--content text-center">
                    <p class="content--description mt-2">
                        Your password has been expired. To change the password, click OK.
                    </p>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <div class="yosync-delete-action-dialog--action yosync-login-footer">
            <button class="yosync-btn solid btn-color button create" matDialogClose (click)="redirect()">
        Ok
    </button>
            
        </div>
    </mat-dialog-actions>
</div>

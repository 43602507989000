import { Directive, ElementRef } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js'

@Directive({
  selector: '[yosyncDateMask]'
})
export class DateMaskDirective {

  mask = [/[0-3]/,/[0-9]/,'-',/[0-1]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]; // dd-mm-yyyy
  maskedInputController;

  constructor(private element: ElementRef) {
		this.maskedInputController = textMask.maskInput({
      inputElement: this.element.nativeElement,
      mask: this.mask
    });
  }

  ngOnDestroy() {
    this.maskedInputController.destroy();
  }

}

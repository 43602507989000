import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageValidateService {

  constructor() { }
	extensions = ['jpg' , 'jpeg', 'png'];

	validateImage(event) {
		let status = false;
		let mimeType = event.files[0].type.split('/');
		mimeType = mimeType[mimeType.length - 1].toLowerCase();
		if (this.extensions.lastIndexOf(mimeType) == -1) {
			status = true
		}
		return status;
	}

}

import { PaymentCallbackComponent } from './payment-callback/payment-callback.component';
import { PaymentLandingComponent } from './payment-landing/payment-landing.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { SpinnerComponent } from './loader/spinner/spinner.component';
import { ToastrComponent } from './toastr/toastr.component';
import { MaterialModule } from '../material.module';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../directives/directives.module';
import { SuccessComponent } from './success/success.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { UserProfileImageEditorComponent } from './user-profile-image-editor/user-profile-image-editor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { UserAddressComponent } from './user-address/user-address.component';
import { UserNetworkComponent } from './user-network/user-network.component';
import { UserProfileIdentityComponent } from './user-profile-identity/user-profile-identity.component';
import { PasswordExpiredAlertComponent } from './password-expired-alert/password-expired-alert.component';



@NgModule({
  declarations: [
    ToastrComponent,
    AlertDialogComponent,
    SpinnerComponent,
    SuccessComponent,
    PasswordStrengthComponent,
    UserProfileImageEditorComponent,
    ImageCropperComponent,
    UserAddressComponent,
    UserNetworkComponent,
    UserProfileIdentityComponent,
    PasswordExpiredAlertComponent,
    PaymentLandingComponent,
    PaymentCallbackComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    ImageCropperModule
  ],
  exports: [
    ToastrComponent,
    AlertDialogComponent,
    SpinnerComponent,
    SuccessComponent,
    PasswordStrengthComponent,
    ImageCropperComponent,
    UserAddressComponent,
    UserNetworkComponent,
    UserProfileIdentityComponent,
    PasswordExpiredAlertComponent
  ]
})
export class ComponentsModule { }

<div fxLayoutAlign="start start" fxLayout="column" fxLayoutGap="16px" class="yosync-profile">
    <div *ngIf="objectKeys(primaryAddress).length > 0">
        <div class="yosync-profile-row" *ngFor="let address of primaryAddress; let lanId = index">
            <div fxLayout="row" class="border-bottom" fxLayoutAlign="space-between center">
                <div class="user address" *ngIf="address.is_permanent_address">Permanent Address</div>
                <div class="lock"><img src="../../../../assets/images/lock.png"></div>
            </div>
            <div class="address-box">
                {{getAddress(address)}}
            </div>
        </div>
    </div>

    <div class="yosync-profile-row form" *ngIf="addressArray?.controls.length > 0">
        <form [formGroup]="addressForm" fxFlex="100" fxFill>
            <div formArrayName="userAddress">
                <div *ngFor="let address of addressArray?.controls let addId = index">
                    <div fxLayout="row" class="border-bottom no-padding" fxLayoutAlign="space-between center" [formGroupName]="addId">
                        <div fxLayout="row" class=" py-8" fxLayoutAlign="start center" fxLayoutGap="3px" fxFlex="35">
                            <div class="lock"><img src="../../../../assets/images/minus.png" (click)="removeAddress(addId)"></div>
                            <div fxLayout="row" class="cp" fxLayoutAlign="start center" fxLayoutGap="3px" [matMenuTriggerFor]="menu" #menuTrigger>
                                <div class="address">
                                    <div class="input" *ngIf="addressArray.controls[addId]['controls'].label.value !== 'OTHER'">
                                        <input type="hidden" formControlName="label" [readonly]="true">
                                        <input formControlName="name_of_label" [readonly]="true">
                                    </div>
                                    <div class="input" *ngIf="addressArray.controls[addId]['controls'].label.value === 'OTHER'">
                                        <input class="p-0" *ngIf="addressArray.controls[addId]['controls'].label_name.value" formControlName="label_name" [readonly]="true">
                                        <input class="p-0" *ngIf="!addressArray.controls[addId]['controls'].label_name.value" formControlName="name_of_label" [readonly]="true">
                                    </div>

                                </div>
                                <div class="lock"><img src="../../../../assets/images/arrow-right.png"></div>
                                <mat-menu #menu="matMenu">
                                    <div *ngFor="let label of availableLabel">
                                        <button mat-menu-item (click)="selectLabel(label.label_code, label.label_name, addId)">{{label.label_name}}</button>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                                        <div class="input label">
                                            <input placeholder="Custom label" formControlName="label_name" (click)="$event.stopPropagation();" />
                                        </div>
                                        <div class="input" fxLayoutAlign="space-between center" *ngIf="addressArray.controls[addId]['controls'].label_name.value">
                                            <button class="button-save add" (click)="selectLabel('OTHER', addressArray.controls[addId].value.label_name, addId)">Add</button>
                                        </div>
                                    </div>
                                </mat-menu>
                            </div>
                            <!-- <div class="msg-error" *ngIf="f[addId].controls?.label.touched && f[addId].controls.label.errors?.required ">Bio is required</div> -->
                        </div>
                        <div class="py-8 kannada ml-10 address border-left" fxLayout="column" fxFlex="65" fxLayoutAlign="space-between center">
                            <input [ngClass]="{'error' : addressArray.controls[addId]['controls'].name.touched && addressArray.controls[addId]['controls'].name.errors?.required }" placeholder="Name" formControlName="name" [yosyncAlphanumericOnly]="formValidation.restrict_regex.name">
                            <div class="msg-error" *ngIf="addressArray.controls[addId]['controls'].name.touched && addressArray.controls[addId]['controls'].name.errors?.required">
                                Name is required</div>


                            <input [ngClass]="{'error' : addressArray.controls[addId]['controls'].address_1.touched && addressArray.controls[addId]['controls'].address_1.errors?.required }" placeholder="Address 1" formControlName="address_1" [yosyncAlphanumericOnly]="formValidation.restrict_regex.address">
                            <div class="msg-error" *ngIf="addressArray.controls[addId]['controls'].address_1.touched && addressArray.controls[addId]['controls'].address_1.errors?.required">
                                Address 1 is required</div>

                            <input placeholder="Address 2" formControlName="address_2" [yosyncAlphanumericOnly]="formValidation.restrict_regex.address">
                            <input [ngClass]="{'error' : addressArray.controls[addId]['controls'].city.touched && addressArray.controls[addId]['controls'].city.errors?.required }" placeholder="City" formControlName="city" [maxlength]="255" [yosyncAlphanumericOnly]="formValidation.restrict_regex.tag_restrict">
                            <div class="msg-error" *ngIf="addressArray.controls[addId]['controls'].city.touched && addressArray.controls[addId]['controls'].city.errors?.required">
                                City is required</div>

                            <input [ngClass]="{'error' : addressArray.controls[addId]['controls'].pincode.touched && addressArray.controls[addId]['controls'].pincode.errors?.required }" placeholder="Pincode" formControlName="pincode" [yosyncAlphanumericOnly]="formValidation.restrict_regex.number_only">
                            <div class="msg-error" *ngIf="addressArray.controls[addId]['controls'].pincode.touched && addressArray.controls[addId]['controls'].pincode.errors?.required">
                                Pincode is required</div>

                            <input [ngClass]="{'error' : addressArray.controls[addId]['controls'].state.touched && addressArray.controls[addId]['controls'].state.errors?.required  }" placeholder="State" [maxlength]="255" formControlName="state" [yosyncAlphanumericOnly]="formValidation.restrict_regex.tag_restrict">
                            <div class="msg-error" *ngIf="addressArray.controls[addId]['controls'].state.touched && addressArray.controls[addId]['controls'].state.errors?.required">
                                State is required</div>

                            <input [ngClass]="{'error' : addressArray.controls[addId]['controls'].country.touched && addressArray.controls[addId]['controls'].country.errors?.required }" placeholder="Country" formControlName="country" [maxlength]="255" [yosyncAlphanumericOnly]="formValidation.restrict_regex.tag_restrict">
                            <div class="msg-error" *ngIf="addressArray.controls[addId]['controls'].country.touched && addressArray.controls[addId]['controls'].country.errors?.required">
                                Country is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="yosync-profile-row">
        <div fxLayout="row" class="border-bottom cp" fxLayoutAlign="space-between center" (click)="addMoreAddress()">
            <div fxLayout="row" class="" fxLayoutAlign="start center" fxLayoutGap="3px" fxFlex="auto">
                <div class="lock"><img src="../../../../assets/images/plus.png"></div>
                <div class="language">add address</div>
            </div>
        </div>
    </div>
    <div class="yosync-login-footer" fxLayout="column" fxFlex="100" fxLayoutAlign="start center">
        <button class="button reset" (click)="createAddress()">Update</button>
    </div>
</div>

<ng-template #deleteDialog let-data>
    <div class="yosync-delete-action-dialog">
        <mat-dialog-content>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
                <div fxFlex="auto" class="text-center mt-1" fxFlexFill>
                    <div class="dh-delete-action-dialog--content text-center">
                        <p class="content--description mt-2">
                            Are you sure you want to delete?
                        </p>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <div class="yosync-delete-action-dialog--action  yosync-login-footer mt-3">
                <button class="yosync-btn solid btn-color" matDialogClose (click)="closeDeleteConfirmation(data.addressInfo)">
          Yes
        </button> &nbsp;&nbsp;
                <button class="yosync-btn outline btn-color" matDialogClose (click)="closeDeleteConfirmation(null)">
          No
        </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>
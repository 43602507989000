import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../http-client/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(private httpService: HttpClientService) { }

  getUserProfile(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/user/profile/view', null);
  }

  updateProfile(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/update', payload);
  }

  createLanguage(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/language/update', payload);
  }

  getLanguages(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/user/contact-info/languages/view', null);
  }

  updateLanguage(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/contact/create/web', payload);
  }

  updateBloodGroup(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/identity/update', payload);
  }

  uploadAsset(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/asset/upload', payload);
  }

  updateProfileImage(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/image/update', payload);
  }

  getLabel(id): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/user/contact-info/labels/view/'+id, null);
  }

  getType(id): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/user/contact-info/types/view/'+id, null);
  }

  addAddress(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/address/update', payload);
  }

  addContact(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/contact/create/web', payload);
  }

  deleteContact(id): Observable<any> {
    return this.httpService.delete(environment.BASE_URL + '/user/profile/contact/delete/'+id, null);
  }

  deleteAddress(id): Observable<any> {
    return this.httpService.delete(environment.BASE_URL + '/user/profile/address/delete/'+id, null);
  }

  deleteLanguage(id): Observable<any> {
    return this.httpService.delete(environment.BASE_URL + '/user/profile/language/delete/'+id, null);
  }

  addSocialMediaLinks(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/social-media/update', payload);
  }

  sendEmailVerification(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/contact/email/send-verification', payload);
  }

  emailVerify(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/contact/email/verify', payload);
  }

  setPrimary(id): Observable<any> {
    return this.httpService.put(environment.BASE_URL + '/user/profile/contact/set/primary/' + id, null);
  }

  sendPhoneOtp(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/contact/phone/send-otp', payload);
  }

  phoneVerify(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/contact/phone/verify', payload);
  }

  createContact(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/contact/create', payload);
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnchormeDirective } from './anchorme/anchorme.directive';
import { HostDirective } from './host/host.directive';
import { AlphanumericOnlyDirective } from './alphanumeric-only.directive';
import { CountdownDirective } from './countdown/countdown.directive';
import { CharacterCountDirective } from './character-count/character-count.directive';
import { DateMaskDirective } from './date-mask/date-mask.directive';

@NgModule({
  declarations: [
    AnchormeDirective,
    HostDirective,
    AlphanumericOnlyDirective,
    CountdownDirective,
    CharacterCountDirective,
    DateMaskDirective
	],
  imports: [
    CommonModule
  ],
  exports: [
    AnchormeDirective,
    HostDirective,
    AlphanumericOnlyDirective,
    CountdownDirective,
    CharacterCountDirective,
    DateMaskDirective
  ]
})
export class DirectivesModule { }

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonStorageService } from '../../storage/common-storage.service';
import { DataStateService } from '../data-state.service';
import { ReferenceName as refName } from '../../../../shared/enum/reference-name.enum';
interface AuthState {
  loggedIn: boolean;
  userInfo: any;
  headerStatus: boolean;
  miniSearch: boolean;
  searchResult: boolean;
  backgroundStatus:boolean;
  isProfileCompleted: boolean;
  tabStatus: string;
  topNavStatus: object;
  isPasswordExpired: boolean;
  availableLabels: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthDataService extends DataStateService<AuthState>  {

  loggedInStatus$: Observable<boolean>;
  miniSearch$: Observable<boolean>;
  searchResult$: Observable<boolean>;
  backgroundStatus$: Observable<boolean>;
  isProfileCompleted$: Observable<boolean>;
  tabStatus$: Observable<string>;
  isPasswordExpired$: Observable<boolean>;

  constructor(private storage: CommonStorageService,) {
    super();
    const initialState: AuthState = {
      loggedIn: false,
      userInfo: [],
      headerStatus: false,
      miniSearch: false,
      searchResult: false,
      backgroundStatus: false,
      isProfileCompleted: false,
      tabStatus: 'INITIATE',
      isPasswordExpired: false,
      topNavStatus: {
        addressStatus:"INITIAL",
        networkStatus:"INITIAL",
        profileStatus:"INITIAL"
      },
      availableLabels: []
    };
    this.state$ = new BehaviorSubject<AuthState>(initialState);

    this.loggedInStatus$ = this.select('loggedIn');
    this.miniSearch$ = this.select('miniSearch');
    this.searchResult$ = this.select('searchResult');
    this.backgroundStatus$ = this.select('backgroundStatus');
    this.isProfileCompleted$ = this.select('isProfileCompleted');
    this.tabStatus$ = this.select('tabStatus');
    this.isPasswordExpired$ = this.select('isPasswordExpired');
  }

  setLoggedInStatus(status: boolean): void {
    this.setState({ loggedIn: status });
  }

  setUserProfileInfo(userInfo: any): void {
    this.setState({ userInfo: userInfo });
  }

  getUserProfileInfo() {
    return this.selectSnapshot('userInfo');
  }

  setHeaderStatus(status: boolean) {
    this.setState({ headerStatus: status })
  }

  setMiniSearch(status: boolean) {
    this.setState({ miniSearch: status });
  }

  setSearchResult(status: boolean) {
    this.setState({ searchResult: status });
  }

  getHeaderStatus() {
    return this.selectSnapshot('headerStatus');
  }
  setBackgroundStatus(backgroundStatus: any): void {
    this.setState({ backgroundStatus: backgroundStatus });
  }

  getBackgroundStatus() {
    return this.selectSnapshot('backgroundStatus');
  }

  setProfileComplete(status: boolean) {
    this.setState({ isProfileCompleted: status });
    this.storage.set(refName.PROFILE_STATUS, status);
  }

  getStatus() {
		return this.storage.get(refName.PROFILE_STATUS);
	}

	getProfileStatus() {
		return this.getStatus();
	}

  setTabStatus(status: string): void {
    this.setState({ tabStatus: status });
  }

  getTabStatus() {
    return this.selectSnapshot('tabStatus');
  }

  setIsPasswordExpired(expired: boolean) {
    this.setState({ isPasswordExpired: expired });
    this.storage.set(refName.IS_PASSWORD_EXPIRED, expired);
  }

  getIsPasswordExpired() {
    return this.storage.get(refName.IS_PASSWORD_EXPIRED);
  }

  setTopNavStatus(status: string, navType: string) {

    let existingStatus = this.getTopNavStatus();
    if (!existingStatus)
    {
      existingStatus = {
        addressStatus:"INITIAL",
        networkStatus:"INITIAL",
        profileStatus:"INITIAL"
      };
      this.storage.set(refName.PROFILE_NAV_STATUS, existingStatus);
      return;
    }
    switch(navType)
    {
      case `PROFILE`:
        existingStatus.profileStatus = status;
        break;
      case `ADDRESS`:
        existingStatus.addressStatus = status;
        break;
      case `NETWORK`:
        existingStatus.networkStatus = status;
        break;
      default:
        break;
    }
    this.setState({topNavStatus: existingStatus});
    this.storage.set(refName.PROFILE_NAV_STATUS, existingStatus);
  }

  getTopNavStatus() {
		return this.storage.get(refName.PROFILE_NAV_STATUS);
	}

  setForgotUsernameData(data: object) {
    this.storage.set(refName.FORGOT_USERNAME, data);
  }

  removeForgotUsernameData() {
    this.storage.remove(refName.FORGOT_USERNAME);
  }

  getForgotUsernameData() {
		return this.storage.get(refName.FORGOT_USERNAME);
	}

  setForgotPasswordData(data: object) {
    this.storage.set(refName.FORGOT_PASSWORD, data);
  }

  removeForgotPasswordData() {
    this.storage.remove(refName.FORGOT_PASSWORD);
  }

  getForgotPasswordData() {
		return this.storage.get(refName.FORGOT_PASSWORD);
	}

  setForgotPasswordHintData(data: object) {
    this.storage.set(refName.FORGOT_PASSWORD_HINT, data);
  }

  removeForgotPasswordHintData() {
    this.storage.remove(refName.FORGOT_PASSWORD_HINT);
  }

  getForgotPasswordHintData() {
		return this.storage.get(refName.FORGOT_PASSWORD_HINT);
	}

  setAvailableLabels(availableLabels: any): void {
    this.setState({ availableLabels: availableLabels });
  }

  getAvailableLabels() {
    return this.selectSnapshot('availableLabels');
  }

  setUserType(userType: string) {
    this.storage.set('user_type', userType);
  }

  getUserType() {
    return this.storage.get('user_type');
  }

  deleteUserType() {
    return this.storage.remove('user_type');
  }

}

export const FORM_VALIDATION = {
  max_length: {
    password: 25,
    username: 25,
    email: 254,
    bio: 260,
    name: 255,
  },
  min_length: {
    password: 8,
    username: 8,
    name: 2,
  },
  restrict_regex: {
    name: '^[a-zA-Z \-\]{1,15}$',
    role_name: '^[a-zA-Z \-\]{1,20}$',
    role_code: '^[a-zA-Z_ \-\]{1,20}$',
    last_name: '^[a-zA-Z \-\]{1,15}$',
    user_name: '^[a-zA-Z0-9_\-\]{1,25}$',
    tag_restrict: '^\s*-?[^<><|>]+\s*$',
    address: '^[a-zA-Z0-9_&,#/. \-\]{1,250}$',
    phone: /^[0-9]{1,10}$/im,
    phone_code: '^[0-9-+]{1,5}$',
    city: '^[a-zA-Z0-9- ]{1,25}$',
    address_name: '^\s*-?[^<>!$%^<|>]+\s*$',
    postal_code: '^[0-9-]{1,10}$',
    number_only: '^[0-9 \-\]{1,}$',
    color_code: '^\s*-?[^<>!$%^<|>]+\s*$'
  },

  validation_regex: {
    name:"^[a-zA-Z \-\]{3,15}$",
		last_name:"^[a-zA-Z \-\]{1,15}$",
		user_name:"^(?!.*__)[a-zA-Z][a-zA-Z0-9_]{7,25}$",
		email:"^(?!.*__)[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$",
		postal_code:'^[0-9]{2,5}(:|\\-)?[0-9]{3,4}',
		tag_restrict:'^\s*-?[^<>$%^<|>]+\s*$',
		password:'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,20}',
    password_new:/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
		login:'^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}|[A-Za-z0-9_]{3,8}$',
		upper_case_only:/[A-Z]/,
		lower_case_only:/[a-z]/,
		special_character:/[!@#$%^&*]/,
		number_only:/\d/,
		website_link:/^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
		phone_code:/^[+][0-9]{1,3}$/,
		// phone:/^(\+\d{1,4}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    phone:/^\d{10}$/,
    pan_no:"[A-Z]{5}[0-9]{4}[A-Z]{1}",
    gst_state_number:/^(3[0-9]|[0-2][0-9])$/,
    gst_other_number:"[1-9A-Z]{1}[A-Z]{1}[0-9A-Z]{1}"
  }
}


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../http-client/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpService: HttpClientService) { }

  getPaymentRequest(id: any): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/payment/request/'+id, null);
  }

  paymentInitiateRazorPay(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/payment/initiate', payload);
  }

}

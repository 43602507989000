import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthDataService } from './core/services/data/auth-data/auth-data.service';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'yosync-user-frontend';
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private authState: AuthDataService,
    private auth: AuthService
  ) {
    const routes = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.replace('/', '') === 'profile') {
          this.authState.setBackgroundStatus(true);

        }
        else {
          this.authState.setBackgroundStatus(false);

        }
      }
    });
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     gtag('js', new Date());
    //     gtag('config', 'G-MWTYNW2466', {
    //       page_path: event.urlAfterRedirects,
    //     });
    //   }
    // });
    this.auth.checkRegisterTokenValidity();
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}



import { PaymentService } from './../../../core/services/api/payment/payment.service';
import { Component, OnInit } from '@angular/core';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { empty, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'yosync-payment-landing',
  templateUrl: './payment-landing.component.html',
  styleUrls: ['./payment-landing.component.scss']
})
export class PaymentLandingComponent implements OnInit {

  subscriptions: Subscription[] = [];
  requestInProgress: boolean = false;
  requestId: string = null;
  orderId: string = null;

  constructor(
    private paymentApi: PaymentService,
    private toast: AppToastrService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const urlParams = this.activatedRoute.queryParams.subscribe(params => {
      this.requestId = params['request_id'];
      if(this.requestId){
        this.getPaymentRequest();
      }
    });
    this.setSubscription(urlParams);
  }

  getPaymentRequest() {
    this.requestInProgress = true;
    const getPaymentRequest = this.paymentApi.getPaymentRequest(this.requestId).subscribe(
      {
        next: (res) => {
          console.log(res.data);
          this.requestInProgress = false;
          let paymentRequest = res.data;
          if(Object.keys(paymentRequest.payment_request).length > 1){
            if(paymentRequest.payment_request.status == 'PENDING'){
              this.orderId = paymentRequest?.order?.id;
              if(this.orderId){
                this.paymentInitiateRazorPay();
              }
            }

          }
        },
        error: (error) => {
          console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(getPaymentRequest);
  }

  paymentInitiateRazorPay(){
    let payload = {
      "payment_gateway": "RAZORPAY",
      "order_id": this.orderId
    }
    const initiatePayment = this.paymentApi.paymentInitiateRazorPay(payload).subscribe(
      {
        next: (res) => {
          console.log(res.data);
          const { payment } = res.data;
          let paymentUrl = payment.short_url;
          window.location.href = paymentUrl;
          this.requestInProgress = false;
        },
        error: (error) => {
          console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(initiatePayment);

  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}

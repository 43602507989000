<div fxLayoutAlign="start start" fxLayout="column" fxLayoutGap="16px" class="yosync-profile">
    <div class="yosync-profile-row">
        <div fxLayout="row" class="border-bottom" fxLayoutAlign="space-between center">
            <div class="user">{{userProfile.first_name}} <span *ngIf="userProfile.last_name"> {{userProfile.last_name}}</span>
            </div>
            <div class="lock"><img src="../../../../assets/images/lock.png"></div>
        </div>
        <div fxLayout="row" class="border-bottom" fxLayoutAlign="space-between center">
            <div class="user-name"><span>@</span>{{userProfile.name}}</div>
            <div class="lock"><img src="../../../../assets/images/lock.png"></div>
        </div>
        <div fxLayout="row" class="border-bottom no-padding" fxLayoutAlign="space-between center">
            <form [formGroup]="updateForm" fxFlex="100" fxLayout="column" fxFill>
                <div formArrayName="languages">
                    <div class="border-bottom p-0" *ngFor="let lan of languageArray?.controls let lanId = index">
                        <div [formGroupName]="lanId">
                            <div fxLayout="row" class="border-right" fxLayoutAlign="start center" fxLayoutGap="3px" fxFlex="50">
                                <div class="lock"><img src="../../../../assets/images/minus.png" (click)="removeLanguage(lanId)"></div>
                                <div fxLayout="row" class="cp" fxLayoutAlign="start center" fxLayoutGap="3px" [matMenuTriggerFor]="menu" #menuTrigger>
                                    <div class="language">
                                        <div class="input">
                                            <input class="input-height" type="hidden" formControlName="languageCode" [readonly]="true" [maxlength]="150">
                                            <input class="input-height" formControlName="languageName" [readonly]="true" [maxlength]="150">
                                        </div>
                                    </div>
                                    <div class="lock"><img src="../../../../assets/images/arrow-right.png"></div>
                                    <mat-menu #menu="matMenu">
                                        <div *ngFor="let label of languageDropdownList">
                                            <button mat-menu-item (click)="selectLabel(label.language_code, label.language_name, lanId)">{{label.language_name}}</button>
                                        </div>
                                    </mat-menu>
                                </div>
                            </div>
                            <div fxLayout="row" fxFlex="50" fxLayoutAlign="space-between center">
                                <div class="py-8 kannada op-53 ml-10" fxLayout="row" fxFlex="87" fxLayoutAlign="space-between center">
                                    <input formControlName="name" type="text" minlength="2" maxlength="255" placeholder="{{langPlaceholders[languageArray.controls[lanId].value.languageCode]}}">
                                </div>
                                <div class="py-8 kannada ml-10" *ngIf="languageArray.controls[lanId]['controls'].name.touched && languageArray.controls[lanId]['controls'].name.invalid" fxLayout="column" fxFlex="13" fxLayoutAlign="space-between end">
                                    <div class="error-tooltip-container" mat-raised-button matTooltip="Enter a valid name" aria-label="Tooltip will display when focused or hovered over">
                                        !
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div fxLayout="row" class="border-bottom cp" fxLayoutAlign="space-between center" (click)="addMoreLanguage()">
            <div fxLayout="row" class="" fxLayoutAlign="start center" fxLayoutGap="3px" fxFlex="auto">
                <div class="lock"><img src="../../../../assets/images/plus.png"></div>
                <div class="language">add language</div>
            </div>
        </div>
    </div>
    <div class="yosync-profile-row no-padding">
        <div fxLayout="row" class="border-bottom no-padding" fxLayoutAlign="space-between center">
            <div class="py-8 kannada border-right" fxLayout="row" fxFlex="50" fxLayoutAlign="space-between center">
                Gender
            </div>
            <div class="py-8 pl-15" fxLayout="row" fxFlex="50" fxLayoutAlign="space-between center">
                <div class="user-name gender" fxLayout="row" fxLayoutAlign="start center" *ngIf="!userProfile.gender">
                    <mat-form-field appearance="fill">
                        <mat-select [(ngModel)]="selectedGender" name="gender">
                            <mat-option value="" disabled="disabled">Select Gender</mat-option>
                            <mat-option value="MALE"> Male</mat-option>
                            <mat-option value="FEMALE"> Female</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="user-name" fxLayout="row" fxLayoutAlign="start center" *ngIf="userProfile.gender && userProfile.gender == 'MALE'">
                    <span>
            <img src="../../../../assets/images/Mars_symbol.svg@2x.png">
          </span>Male
                </div>
                <div class="user-name" fxLayout="row" fxLayoutAlign="start center" *ngIf="userProfile.gender && userProfile.gender == 'FEMALE'">
                    <span>
            <span class="material-icons">female</span>
                    </span>Female
                </div>
                <div class="lock" *ngIf="userProfile.gender"><img src="../../../../assets/images/lock.png"></div>
            </div>
        </div>
    </div>
    <div class="yosync-profile-row no-padding">
        <div fxLayout="row" class="border-bottom no-padding" fxLayoutAlign="space-between center">
            <div class="py-8  kannada border-right" fxLayout="row" fxFlex="50" fxLayoutAlign="space-between center">
                Date of Birth
            </div>
            <div class="py-8 pl-15" fxLayout="row" fxFlex="50" fxLayoutAlign="space-between center">
                <div class="user-name" fxLayout="row" fxLayoutAlign="start center">
                    {{userProfile.date_of_birth | date: 'dd-MM-yyyy'}}</div>
                <div class="lock"><img src="../../../../assets/images/lock.png"></div>
            </div>
        </div>
    </div>
    <div class="yosync-profile-row no-padding">
        <div fxLayout="row" class="border-bottom no-padding" fxLayoutAlign="space-between center">
            <div class="py-8 kannada border-right" fxLayout="row" fxFlex="50" fxLayoutAlign="space-between center">
                Blood Group
            </div>
            <div class="" fxLayout="row" fxFlex="50" fxLayoutAlign="space-between center">
                <div class="user-name blood" fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="fill">
                        <mat-select [(ngModel)]="selectedBloodGroup" name="food">
                            <mat-option value="" disabled="disabled">Select Blood Group</mat-option>
                            <mat-option *ngFor="let blood of bloodGroup" [value]="blood.code">
                                {{blood.name}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="yosync-login-footer" fxLayout="column" fxFlex="100" fxLayoutAlign="start center" (click)="languageCreate()">
        <button class="button reset">Update</button>
    </div>
</div>
<ng-template #deleteDialog let-data>
    <div class="yosync-delete-action-dialog">
        <mat-dialog-content>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
                <div fxFlex="auto" class="text-center mt-1" fxFlexFill>
                    <div class="dh-delete-action-dialog--content text-center">
                        <p class="content--description mt-2">
                            Are you sure you want to delete?
                        </p>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <div class="yosync-delete-action-dialog--action mt-3">
                <button class="yosync-btn solid btn-color" matDialogClose (click)="closeDeleteConfirmation(data.languageInfo)">
          Yes
        </button> &nbsp;&nbsp;
                <button class="yosync-btn outline btn-color" matDialogClose (click)="closeDeleteConfirmation(null)">
          No
        </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>
<ul class="circle">
  <!-- {{active0 }}, {{active1}}, {{active2}}, {{active3}}, {{active4}} -->
  <li class="" [style.background-color]="active0"></li>
  <li class="" [style.background-color]="active1"></li>
  <li class="" [style.background-color]="active2"></li>
  <li class="" [style.background-color]="active3"></li>
  <li class="" [style.background-color]="active4"></li>
</ul>
<!-- <div class="green" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
  <span>{{msg}}</span>
</div> -->

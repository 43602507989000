<div class="container v-100 d-flex align-items-center">
  <div class="mx-auto">
      <div class="col-12 text-center">
          <p>Redirecting</p>
          <p class="redirect">to Payment Gatway</p>
      </div>
      <div class="col-12 text-center">
          <div class="snippet" data-title=".dot-flashing">
              <div class="stage">
                  <div class="dot-flashing"></div>
              </div>
          </div>
      </div>
      <div class="col-12 text-center">
          <p class="footer">Do not close or navigate to a different page</p>
      </div>
  </div>

</div>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProfileApiService } from 'src/app/core/services/api/profile-api/profile-api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AuthDataService } from 'src/app/core/services/data/auth-data/auth-data.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { FORM_VALIDATION } from 'src/app/data/form-validation-config';

@Component({
  selector: 'yosync-user-network',
  templateUrl: './user-network.component.html',
  styleUrls: ['./user-network.component.scss']
})
export class UserNetworkComponent implements OnInit, OnDestroy {

  @Input() userProfile: any;
  @Input() showTab: any;
  //@Input() availableLabel: any;
  @Output() navigateTab: EventEmitter<any> = new EventEmitter();
  @ViewChild('deleteDialog') deleteDialog: TemplateRef<any>;
  @ViewChild('networkDialog') networkDialog: TemplateRef<any>;
  @ViewChild('verificationDialog') verificationDialog: TemplateRef<any>;
  @ViewChild('primaryDialog') primaryDialog: TemplateRef<any>;
  subscriptions: Subscription[] = [];
  identityForm!: FormGroup;
  formValidation = FORM_VALIDATION;
  requestInProgress: boolean = false;
  websiteUrl: any = {};
  socialMediaLink: string = '';
  otp: string;
  verifyOtperror: string;
  socialMediaError: string;
  addedLabels: any;
  availableLabel: any;
  sobjectKeys = Object.keys;

  socialMediaTypes = {
    FACEBOOK: "SOCIAL_MEDIA_FACEBOOK_LINK",
    TWITTER: "SOCIAL_MEDIA_TWITTER_LINK",
    KOO: "SOCIAL_MEDIA_KOO_LINK",
    LINKEDIN: "SOCIAL_MEDIA_LINKEDIN_LINK",
    INSTAGRAM: "SOCIAL_MEDIA_INSTAGRAM_LINK",
  }

  constructor(
    private fb: FormBuilder,
    private toast: AppToastrService,
    private profileApi: ProfileApiService,
    private auth: AuthService,
    public dialog: MatDialog,
    private authState: AuthDataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const availableLabels = this.authState.select('availableLabels').subscribe(res => {
      this.availableLabel = res;
      //console.log(this.availableLabel);
    });
    this.setSubscription(availableLabels);
    this.availableLabel = this.availableLabel.filter(x => (x.label_code !== 'MOBILE' && x.label_code !== 'OTHER') || (x.label_code === 'OTHER' && x.is_system === false));

    this.identityForm = this.fb.group({
      mobile: this.fb.array([]),
      email: this.fb.array([]),
      link: ['', [Validators.pattern(this.formValidation.validation_regex.website_link)]],
    });
    const userInfo = this.authState.select('userInfo').subscribe(res => {
      this.userProfile = res;
      this.patchUserInfo();
    });
    this.setSubscription(userInfo);

    this.identityForm.valueChanges.subscribe(() => {
      if (this.identityForm.invalid)
      {
        this.authState.setTabStatus('NETWORK_INCOMPLETE');
      }
      else
      {
        if (this.identityForm.value.link)
        {
          Object.keys(this.socialMediaTypes).forEach(socialMediaType => {
            let res = this.getsocialMediaLinks(socialMediaType);
            if (res)
            {
              this.authState.setTabStatus('NETWORK_COMPLETED');
              return;
            }
          });
        }
      }
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getsocialMediaLinks(linkType){
    let linkExist = this.userProfile.contact_information?.find(x => x.type.code == this.socialMediaTypes[linkType]);
    // console.log(linkExist);
    if(linkExist){
      return true;
    }
    return false;
  }

  patchUserInfo() {
    this.websiteUrl = this.userProfile.contact_information?.find(x => x.type.code == 'WEBSITE');
    if (this.websiteUrl && Object.keys(this.websiteUrl).length > 0) {
      // this.identityForm = this.fb.group({
      //   mobile: this.fb.array([]),
      //   email: this.fb.array([]),
      //   link: [this.websiteUrl.link, [Validators.pattern(this.formValidation.validation_regex.website_link)]],
      // });
      this.identityForm.patchValue({
        link: this.websiteUrl.link
      });
      // this.identityForm.value.link = this.websiteUrl.link;
    }
    // else {
      // this.identityForm = this.fb.group({
      //   mobile: this.fb.array([]),
      //   email: this.fb.array([]),
      //   link: ['', [Validators.pattern(this.formValidation.validation_regex.website_link)]],
      // });
    // }
    this.requestInProgress = true;
    /* let emailPatch = this.fb.group({
      type: 'EMAIL',
      label: 'HOME',
      email: this.userProfile.email,
      is_primary: true,
      is_verified: true,
      label_name: ''
    });
    this.identityEmailArray.push(emailPatch); */

    let primaryEmail;
    let emailInformation = this.userProfile.contact_information?.filter(x => x.type.code == 'EMAIL');
    if (emailInformation && emailInformation.length > 0) {
      emailInformation.forEach(e => {
        if (e.is_primary)
        {
          let emailExist = this.fb.group({
            type: e.type.code,
            label: 'EMAIL',
            name_of_label: 'Email',
            original_label: e.label.code,
            email: e.email,
            is_primary: e.is_primary,
            is_verified: e.is_verified,
            label_name: '',
            contact_id: e.id
          });
          primaryEmail = {'email' : e.email}
          let index = this.identityEmailArray.controls.findIndex(x=>x.value.is_primary);
          if(index > -1){
            this.identityEmailArray.controls[index] = emailExist;
          } else {
            this.identityEmailArray.push(emailExist);
          }
        }
        else
        {
          let emailExist = this.fb.group({
            type: e.type.code,
            label: e.label.code,
            name_of_label: e.label.name,
            email: [e.email, [Validators.required, Validators.pattern(this.formValidation.validation_regex.email)]],
            is_primary: e.is_primary,
            is_verified: e.is_verified,
            label_name: '',
            contact_id: e.id
          });
          let index = this.identityEmailArray.controls.findIndex(x=>(x.value.label === e.label.code || x.value.label_name.toUpperCase() === e.label.code.toUpperCase()) && x.value.email === e.email);
          if(index > -1){
            this.identityEmailArray.controls[index] = emailExist;
          } else {
            this.identityEmailArray.push(emailExist);
          }
        }
      });
      let removeIndex = this.identityEmailArray.controls.findIndex(x=>x.value.email === primaryEmail.email && !x.value.is_primary)
      if (removeIndex > -1) {
        this.identityEmailArray.removeAt(removeIndex);
      }
      // console.log(removeIndex, primaryEmail);
      this.identityForm.patchValue({
        'email': this.identityEmailArray.value
      });
    }

    /* let mobilePatch = this.fb.group({
      type: 'PHONE',
      label: 'HOME',
      phone: this.userProfile.phone,
      country_code: this.userProfile.country_phone_code,
      is_primary: true,
      is_verified: true,
      label_name: '',
    });
    this.identityMobileArray.push(mobilePatch); */
    let primaryMobile;
    let phoneInformation = this.userProfile.contact_information?.filter(x => x.type.code == 'PHONE');
    if (phoneInformation && phoneInformation.length > 0) {
      phoneInformation.forEach(e => {
        if (e.is_primary)
        {
          let mobileExist = this.fb.group({
            type: e.type.code,
            label: 'MOBILE',
            name_of_label: 'Mobile',
            original_label: e.label.code,
            phone: e.phone,
            country_code: e.country_code,
            is_primary: e.is_primary,
            is_verified: e.is_verified,
            label_name: '',
            contact_id: e.id
          });
          primaryMobile = {'phone': e.phone, 'country_code': e.country_code};
          let index = this.identityMobileArray.controls.findIndex(x=>x.value.is_primary);
          if(index > -1){
            this.identityMobileArray.controls[index] = mobileExist;
          } else {
            this.identityMobileArray.push(mobileExist);
          }
        }
        else
        {
          let mobileExist = this.fb.group({
            type: e.type.code,
            label: e.label.code,
            name_of_label: e.label.name,
            phone: [e.phone, [Validators.required, Validators.pattern(this.formValidation.validation_regex.phone)]],
            country_code: [e.country_code, [Validators.required, Validators.pattern(this.formValidation.validation_regex.phone_code)]],
            is_primary: e.is_primary,
            is_verified: e.is_verified,
            label_name: '',
            contact_id: e.id
          });
          let index = this.identityMobileArray.controls.findIndex(x=>(x.value.label === e.label.code || x.value.label_name.toUpperCase() === e.label.code.toUpperCase()) && x.value.phone === e.phone && x.value.country_code === e.country_code);
          console.log(this.identityMobileArray.controls, index, e);
          if(index > -1){
            this.identityMobileArray.controls[index] = mobileExist;
          } else {
            this.identityMobileArray.push(mobileExist);
          }
        }
      });
      let removeIndex = this.identityMobileArray.controls.findIndex(x => x.value.phone === primaryMobile.phone && x.value.country_code === primaryMobile.country_code && !x.value.is_primary)
      if (removeIndex > -1) {
        this.identityMobileArray.removeAt(removeIndex);
      }
      this.identityForm.patchValue({
        'mobile': this.identityMobileArray.value
      });
    }
    // console.log(this.identityForm);
    this.requestInProgress = false;
  }

  selectMobileLabel(labelCode, labelName, index) {
    if (labelName != '' && labelName != undefined)
    {
      this.identityMobileArray.controls[index].get('label').setValue(labelCode);
      this.identityMobileArray.controls[index].get('name_of_label').setValue(labelName);
      if (labelCode == 'OTHER')
      {
        let obj = {
          id: '',
          is_system: 0,
          label_code: labelCode,
          label_name: labelName,
          status: "ACTIVE",
          user_id: ""
        }
        this.addedLabels = obj;
      }
    }
  }

  selectEmailLabel(labelCode, labelName, index) {
    if (labelName != '' && labelName != undefined)
    {
      this.identityEmailArray.controls[index].get('label').setValue(labelCode);
      this.identityEmailArray.controls[index].get('name_of_label').setValue(labelName);
      if (labelCode == 'OTHER')
      {
        let obj = {
          id: '',
          is_system: 0,
          label_code: labelCode,
          label_name: labelName,
          status: "ACTIVE",
          user_id: ""
        }
        this.addedLabels = obj;
      }
    }
  }

  addMobile() {
    const results = this.availableLabel.filter(({ label_code: id1 }) => !this.identityForm.value.mobile.some(({ label: id2 }) => id2 === id1));
    let labelCode, labelName;
    if(results.length > 0){
       labelCode = results[0].label_code;
       labelName = results[0].label_name;
    } else {
      const index = Math.floor(this.availableLabel.length * Math.random());
      const pickAnyLabel = this.availableLabel[index];
      labelCode = pickAnyLabel.label_code;
      labelName = pickAnyLabel.label_name;
    }
    if (this.addedLabels != undefined)
    {
      this.availableLabel.push(this.addedLabels);
      this.addedLabels = undefined;
      this.authState.setAvailableLabels(this.availableLabel);
    }

    return this.fb.group({
      type: ['PHONE'],
      label: labelCode,
      name_of_label: labelName,
      phone: ['', [Validators.required, Validators.pattern(this.formValidation.validation_regex.phone)]],
      country_code: ['+91', [Validators.required, Validators.pattern(this.formValidation.validation_regex.phone_code)]],
      is_primary: false,
      is_verified: false,
      label_name: ''
    });
  }

  addMoreMobile() {
    this.identityMobileArray.push(this.addMobile());
  }

  removeMobile(index) {
    if (index > 0) {
      this.deleteConfirmation(index, 'PHONE');
      // return;
      // this.deleteConfirmation(index);
      // let removevalue = this.identityMobileArray.controls[index].value;
      // console.log(removevalue);
    }
  }

  deleteConfirmation(index, ContactType) {
    let contact;
    if (ContactType == 'PHONE') {
      contact = this.identityMobileArray.controls[index]?.value;
      contact['index'] = index;
    } else {
      contact = this.identityEmailArray.controls[index]?.value;
      contact['index'] = index;
    }
    this.dialog.open(this.deleteDialog, {
      height: '220px',
      width: '450px',
      disableClose: true,
      data: {
        contactInfo: contact
      }
    })
  }
  closeDeleteConfirmation(contactInfo) {
    if (contactInfo && contactInfo.type == 'PHONE') {
      this.identityMobileArray.removeAt(contactInfo.index);
      if (contactInfo.contact_id) {
        this.deleteContact(contactInfo);
      }
    }
    if (contactInfo && contactInfo.type == 'EMAIL') {
      this.identityEmailArray.removeAt(contactInfo.index);
      if (contactInfo.contact_id) {
        this.deleteContact(contactInfo);
      }
    }
  }
  addEmail() {
    const results = this.availableLabel.filter(({ label_code: id1 }) => !this.identityForm.value.email.some(({ label: id2 }) => id2 === id1));
    let labelCode, labelName;
    if(results.length > 0){
       labelCode = results[0].label_code;
       labelName = results[0].label_name;
    } else {
      const index = Math.floor(this.availableLabel.length * Math.random());
      const pickAnyLabel = this.availableLabel[index];
      labelCode = pickAnyLabel.label_code;
      labelName = pickAnyLabel.label_name;
    }
    if (this.addedLabels != undefined)
    {
      this.availableLabel.push(this.addedLabels);
      this.addedLabels = undefined;
      this.authState.setAvailableLabels(this.availableLabel);
    }

    return this.fb.group({
      type: 'EMAIL',
      label: labelCode,
      name_of_label: labelName,
      email: ['', [Validators.required, Validators.pattern(this.formValidation.validation_regex.email)]],
      is_primary: false,
      is_verified: false,
      label_name: ''
    });
  }

  addMoreEmail() {
    this.identityEmailArray.push(this.addEmail());
  }


  removeEmail(index) {
    if (index > 0) {
      this.deleteConfirmation(index, 'EMIAL');
      // let removevalue = this.identityEmailArray.controls[index].value;
      // this.identityEmailArray.removeAt(index);
    }
  }

  sendVerification(contactInfo, index) {
    this.otp = '';
    this.verifyOtperror = '';

    if (contactInfo.type == 'PHONE')
    {
      if (contactInfo.phone == '' || contactInfo.phone == undefined || contactInfo.phone.length != 10 )
      {
        this.toast.error('Enter a valid mobile number.');
      }
      else if (contactInfo.country_code == '' || contactInfo.country_code == undefined)
      {
        this.toast.error('Enter a valid country code.');
      }
      else
      {
        let verificationData = {
          'phone_no': contactInfo.phone,
          'country_code': contactInfo.country_code,
          'resend': false
        };

        const sendPhoneOtp = this.profileApi.sendPhoneOtp(verificationData).subscribe(
          {
            next: (res) => {
              this.requestInProgress = false;
              this.toast.success(res.message);
            },
            error: (error) => {
              console.log(error);
              this.requestInProgress = false;
              let errors;
              if (error.data != undefined)
              {
                errors = error.data.errors;
              }
              if (errors.constructor === Object)
              {
                errors = error.message;
              }
              this.toast.error(errors);
            },
          }
        )
        this.setSubscription(sendPhoneOtp);

        this.dialog.open(this.verificationDialog, {
          height: '200px',
          width: '400px',
          disableClose: true,
          data: {
            contactInfo,
            index
          }
        })
      }
    }
    else if (contactInfo.type == 'EMAIL')
    {
      if (contactInfo.email == '' || contactInfo.email == undefined)
      {
        this.toast.error('Email is required.');
      }
      else
      {
        let verificationData = {
          'email': contactInfo.email,
          'resend': false
        };

        this.requestInProgress = true;
        const sendEmailVerification = this.profileApi.sendEmailVerification(verificationData).subscribe(
          {
            next: (res) => {
              this.requestInProgress = false;
              this.toast.success(res.message);
            },
            error: (error) => {
              this.requestInProgress = false;
              let errors;
              if (error.data != undefined)
              {
                errors = error.data.errors;
              }
              if (errors.constructor === Object)
              {
                errors = error.message;
              }
              this.toast.error(errors);
            },
          }
        )
        this.setSubscription(sendEmailVerification);

        this.dialog.open(this.verificationDialog, {
          height: '200px',
          width: '400px',
          disableClose: true,
          data: {
            contactInfo,
            index
          }
        })
      }
    }
  }

  verifyContact(contactInfo, index) {
    if(!contactInfo){
      return;
    }
    if (contactInfo.type == 'PHONE')
    {
      if(!contactInfo.phone){
        this.toast.error('Enter the phone number');
        return;
      }

      let payload = {
        'phone_no': contactInfo.phone,
        'country_code': contactInfo.country_code,
        'phone_verification_code': this.otp
      };

      this.requestInProgress = true;
      const phoneVerify = this.profileApi.phoneVerify(payload).subscribe(
        {
          next: (res) => {
            this.requestInProgress = false;
            this.otp = '';
            this.verifyOtperror = '';
            this.toast.success(res.message);
            let userData = this.authState.getUserProfileInfo();
            for (let i = 0; i < userData.contact_information.length; i++)
            {
              if (userData.contact_information[i].id == contactInfo.contact_id)
              {
                userData.contact_information[i].is_verified = true;
              }
            }
            this.identityMobileArray.controls[index].get('is_verified').setValue(true);
            this.auth.setUserInfo(userData);
            this.dialog.closeAll();
          },
          error: (error) => {
            this.requestInProgress = false;
            let errors;
            if (error.data != undefined)
            {
              errors = error.data.errors;
            }
            if (errors.constructor === Object)
            {
              errors = error.message;
            }
            this.verifyOtperror = '* ' + errors;
            //this.toast.error(errors);
          },
        }
      )

      this.setSubscription(phoneVerify);
    }

    else if (contactInfo.type == 'EMAIL')
    {
      if(!contactInfo.email){
        this.toast.error('Enter the email');
        return;
      }

      let payload = {
        'email': contactInfo.email,
        'email_verification_code': this.otp
      };

      this.requestInProgress = true;
      const emailVerify = this.profileApi.emailVerify(payload).subscribe(
        {
          next: (res) => {
            this.requestInProgress = false;
            this.otp = '';
            this.verifyOtperror = '';
            this.toast.success(res.message);
            let userData = this.authState.getUserProfileInfo();
            for (let i = 0; i < userData.contact_information.length; i++)
            {
              if (userData.contact_information[i].id == contactInfo.contact_id)
              {
                userData.contact_information[i].is_verified = true;
              }
            }
            this.identityEmailArray.controls[index].get('is_verified').setValue(true);
            this.auth.setUserInfo(userData);
            this.dialog.closeAll();
          },
          error: (error) => {
            this.requestInProgress = false;
            let errors;
            if (error.data != undefined)
            {
              errors = error.data.errors;
            }
            if (errors.constructor === Object)
            {
              errors = error.message;
            }
            this.verifyOtperror = '* ' + errors;
            //this.toast.error(errors);
          },
        }
      )

      this.setSubscription(emailVerify);
    }
  }

  setPrimaryDialog(contactInfo, index) {
    this.dialog.open(this.primaryDialog, {
      height: '200px',
      width: '400px',
      disableClose: true,
      data: {
        contactInfo,
        index
      }
    })
  }

  setPrimary(contactInfo, index) {
      this.requestInProgress = true;
      const setPrimaryApi = this.profileApi.setPrimary(contactInfo.contact_id).subscribe(
        {
          next: (res) => {
            this.requestInProgress = false;
            this.otp = '';
            this.verifyOtperror = '';
            this.toast.success(res.message);
            this.auth.setUserInfo(res.data.user);
          },
          error: (error) => {
            this.requestInProgress = false;
            this.verifyOtperror = '* ' + error.message;
            let errors;
            if (error.data != undefined)
            {
              errors = error.data.errors;
            }
            if (errors.constructor === Object)
            {
              errors = error.message;
            }
            this.toast.error(errors);
          },
        }
      )

      this.setSubscription(setPrimaryApi);
  }

  get identityMobileArray() {
    return (this.identityForm.get('mobile') as FormArray);
  }

  get identityEmailArray() {
    return (this.identityForm.get('email') as FormArray);
  }

  updateContact() {
    if (this.identityForm.invalid) {
      this.requestInProgress = false;
      this.identityForm.markAllAsTouched();
      return;
    }

    let payload = {
      "contacts": []
    }

    if (this.identityForm.value.mobile.length > 0) {
      this.identityForm.value.mobile.forEach(element => {
        element.label = (element.label == 'MOBILE') ? element.original_label : element.label;
        payload['contacts'].push(element);
      });
    }
    if (this.identityForm.value.email.length > 0) {
      this.identityForm.value.email.forEach(element => {
        element.label = (element.label == 'EMAIL') ? element.original_label : element.label;
        payload['contacts'].push(element);
      });
    }
    if (this.identityForm.value.link && this.websiteUrl && Object.keys(this.websiteUrl).length > 0){
      payload['contacts'].push({
        type: 'WEBSITE',
        label: 'HOME',
        link: this.identityForm.value.link,
        contact_id: this.websiteUrl.id
      });
    } else if (this.identityForm.value.link) {
      payload['contacts'].push({
        type: 'WEBSITE',
        label: 'HOME',
        link: this.identityForm.value.link
      });
    }
    if(payload['contacts'].length == 0){
      this.authState.setTabStatus('INITIATE');
      this.router.navigate(['/dashboard/dashboard']);
      return;
    }
    this.requestInProgress = true;
    const updateNetwork = this.profileApi.addContact(payload).subscribe(
      {
        next: (res) => {
          // console.log(res.data);
          this.requestInProgress = false;
          // this.navigateTab.emit('ADDRESS');
          this.auth.setUserInfo(res.data.user);
          this.authState.setProfileComplete(true);
          this.authState.setTabStatus('INITIATE');
          this.router.navigate(['/dashboard/dashboard']);
        },
        error: (error) => {
          console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(updateNetwork);
  }

  deleteContact(contactDetail) {
    let contactId = contactDetail.contact_id;
    this.requestInProgress = true;
    const updateNetwork = this.profileApi.deleteContact(contactId).subscribe(
      {
        next: (res) => {
          console.log(res.data);
          this.requestInProgress = false;
          let userData = this.authState.getUserProfileInfo();
          for (let i = 0; i < userData.contact_information.length; i++)
          {
            if (userData.contact_information[i].id == contactDetail.contact_id)
            {
              userData.contact_information.splice(i, 1);
            }
          }
          this.auth.setUserInfo(userData);
          this.toast.success(res.message);
        },
        error: (error) => {
          console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(updateNetwork);
  }


  addSocialMedia(networkType) {
    this.socialMediaError = '';
    let socialMedia = this.userProfile.contact_information.find(x=>x.type.code == this.socialMediaTypes[networkType]) || null;
    if(socialMedia && Object.keys(socialMedia).length > 0){
      this.socialMediaLink = socialMedia.link;
    } else {
      this.socialMediaLink = '';
    }
    this.dialog.open(this.networkDialog, {
      height: '200px',
      width: '400px',
      disableClose: true,
      data: {
        networkType
      }
    })
  }

  createNetwork(networkType) {
    if(!networkType){
      return;
    }
    if(!this.socialMediaLink){
      this.socialMediaError = '* Enter the link';
      return;
    }
    let payload = {
      "social_media": [
        {
          "type": this.socialMediaTypes[networkType],
          "value": this.socialMediaLink
        }
      ]
    }
    this.requestInProgress = true;
    const updateNetwork = this.profileApi.addSocialMediaLinks(payload).subscribe(
      {
        next: (res) => {
          //console.log(res.data);
          this.requestInProgress = false;
          this.socialMediaLink = '';
          this.auth.setUserInfo(res.data.user);
          this.toast.success('Link updated successfully');
          this.dialog.closeAll();
        },
        error: (error) => {
          //console.log(error);
          this.requestInProgress = false;
          this.socialMediaError = '* ' + error.message;
        },
      }
    )
    this.setSubscription(updateNetwork);
  }

  createMobilePayload(index, actionType){
    this.verifyOtperror = '';
    if (this.formValidation.validation_regex.phone.test(this.identityForm.value.mobile[index].phone) && this.formValidation.validation_regex.phone_code.test(this.identityForm.value.mobile[index].country_code)) {
      let mobile = this.identityForm.value.mobile[index];
      this.identityForm.value.mobile[index].temp_id = index;
      mobile.label = (mobile.label == 'MOBILE') ? mobile.original_label : mobile.label;
      let payload = mobile;
      if(actionType == 1){
        // console.log(payload);
        // return;
        this.createContact(payload, index);

      }
    }
  }

  createEmailPayload(index, actionType){
    if (new RegExp(this.formValidation.validation_regex.email).test(this.identityForm.value.email[index].email)) {
      let email = this.identityForm.value.email[index];
      this.identityForm.value.email[index].temp_id = index;

      email.label = (email.label == 'EMAIL') ? email.original_label : email.label;
      let payload = email;
      if(actionType === 1){
        // console.log(payload);
        // return;
        this.createContact(payload, index);
      }
    }
  }

  createContact(payload, index) {
    this.requestInProgress = true;
    const updateNetwork = this.profileApi.createContact(payload).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          this.auth.setUserInfo(res.data.user);
          this.dialog.open(this.verificationDialog, {
            height: '200px',
            width: '400px',
            disableClose: true,
            data: {
              contactInfo:payload,
              index
            }
          })
        },
        error: (error) => {
          console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(updateNetwork);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }


}

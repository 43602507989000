import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProfileApiService } from 'src/app/core/services/api/profile-api/profile-api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AuthDataService } from 'src/app/core/services/data/auth-data/auth-data.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { FORM_VALIDATION } from 'src/app/data/form-validation-config';

@Component({
  selector: 'yosync-user-profile-identity',
  templateUrl: './user-profile-identity.component.html',
  styleUrls: ['./user-profile-identity.component.scss']
})
export class UserProfileIdentityComponent implements OnInit {

  @Input() userProfile: any;
  @Input() languages: any;
  @Input() showTab: any;
  @Input() availableLabel: any;
  @Output() navigateTab: EventEmitter<any> = new EventEmitter();
  @ViewChild('deleteDialog') deleteDialog: TemplateRef<any>;
  subscriptions: Subscription[] = [];
  updateForm!: FormGroup;
  formValidation = FORM_VALIDATION;
  requestInProgress: boolean = false;
  languageResultCheck: boolean = true;
  selectedBloodGroup: string = 'O+';
  selectedGender: string = '';
  isFirstLogin: boolean = true;
  values: Array<string> = [];
  langPlaceholders: Array<string> = [];
  languageDropdownList:any = [];

  bloodGroup = [
    {
      code: 'B+',
      name: 'B+'
    },
    {
      code: 'B-',
      name: 'B-'
    },
    {
      code: 'O+',
      name: 'O+'
    },
    {
      code: 'O-',
      name: 'O-'
    },
    {
      code: 'AB+',
      name: 'AB+'
    },
    {
      code: 'AB-',
      name: 'AB-'
    },
    {
      code: 'A-',
      name: 'A-'
    },
    {
      code: 'A+',
      name: 'A+'
    }
  ];

  constructor(
    private fb: FormBuilder,
    private toast: AppToastrService,
    private profileApi: ProfileApiService,
    private auth: AuthService,
    private authState: AuthDataService,
    public dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.selectedBloodGroup = (this.userProfile.blood_group) ? this.userProfile.blood_group : '';
    this.updateForm = this.fb.group({
      languages: this.fb.array([]),
    });
    this.checkUpdateLanguage();

    this.updateForm.valueChanges.subscribe(() => {
      if (this.updateForm.invalid)
      {
        this.authState.setTabStatus('PROFILE_INCOMPLETE');
      }
      else
      {
        this.authState.setTabStatus('PROFILE_COMPLETED');
      }
    });

    this.languages.forEach((language, key) => {
      this.langPlaceholders[language.language_code] = language.placeholder;
    });
    this.languageDropdownList = this.languages;
  }

  checkUpdateLanguage() {
    let languageExist = this.userProfile.contact_information?.filter(x => x.language);
    if(languageExist && languageExist.length>0){
      this.updateForm = this.fb.group({
        languages: this.fb.array([]),
      });
      languageExist.forEach(e => {
        let lanExist = this.fb.group({
          languageCode: e.language.language_code,
          languageName: e.language.language_name,
          name: [e.name, [Validators.required, Validators.maxLength(this.formValidation.max_length.name), Validators.minLength(this.formValidation.min_length.name)]],
          contact_id: e.id
        });
        this.languageArray.push(lanExist);
      });
    }
  }

  selectLabel(labelCode, languageName, index) {
    this.languageArray.controls[index].get('languageCode').setValue(labelCode);
    this.languageArray.controls[index].get('languageName').setValue(languageName);
    this.values.push(labelCode);
  }

  // onChange(event, index) {
  //   this.values.push(event.value);
  // }

  addLanguage() {
    const results = this.languages.filter(({ language_code: id1 }) => !this.updateForm.value.languages.some(({ languageCode: id2 }) => id2 === id1));
    let labelCode, labelName;
    console.log(results);
    if(results.length > 0){
      labelCode = results[0].language_code;
      labelName = results[0].language_name;
    }
    this.languageDropdownList = results;
    return this.fb.group({
      languageCode: labelCode,
      languageName: labelName,
      name: ['', [Validators.required, Validators.maxLength(this.formValidation.max_length.name), Validators.minLength(this.formValidation.min_length.name)]],
    });
  }

  addMoreLanguage() {
    if (this.languages.length == this.languageArray.length) {
      return;
    }
    this.languageArray.push(this.addLanguage());
  }

  removeLanguage(index) {
    this.deleteConfirmation(index)
  }

  get languageArray() {
    return (this.updateForm.get('languages') as FormArray);
  }

  languageCreate() {
    if(this.updateForm.value.languages.length == 0 && !this.selectedBloodGroup && !this.selectedGender){
      this.authState.setTabStatus('ADDRESS_INITIAL');
      this.navigateTab.emit('ADDRESS');
      return;
    }

    if (this.updateForm.invalid) {
      this.requestInProgress = false;
      this.updateForm.markAllAsTouched();
      return;
    }

    this.updateForm.value.languages.forEach((e, index) => {
      let payload = {
        "type_code": "NAME",
        "language_code": e.languageCode,
        "name": e.name,
        "id": (e?.contact_id) ? e.contact_id : ''
      }

      if (!e.name || e.name.length < 2 || e.name.length > 255)
      {
        //this.toast.error('Name is invalid');
        this.languageResultCheck = false;
        return;
      }
      else
      {
        this.createLanguage(payload)
      }
    });

    if((this.selectedBloodGroup || this.selectedGender) && this.languageResultCheck){
      this.updateBloodGroup();
    }
  }

  createLanguage(payload) {
    this.requestInProgress = true;
    const updateLanguage = this.profileApi.createLanguage(payload).subscribe(
      {
        next: (res) => {
          //console.log(res.data);
          this.requestInProgress = false;
          // this.navigateTab.emit('PROFILE');
          this.auth.setUserInfo(res.data.user);
          this.languageResultCheck = true;
          if(!this.selectedBloodGroup && !this.selectedGender){
            this.authState.setTabStatus('ADDRESS_INITIAL');
            this.navigateTab.emit('ADDRESS');
          }
        },
        error: (error) => {
          this.requestInProgress = false;
          this.languageResultCheck = false;
          let errors;
          if (error.data != undefined)
          {
            errors = error.data.errors;
          }
          if (errors.constructor === Object)
          {
            errors = error.message;
          }
          this.toast.error(errors);
        },
      }
    )
    this.setSubscription(updateLanguage);
  }

  updateBloodGroup() {
    this.requestInProgress = true;
    let payload = {
      "blood_group": this.selectedBloodGroup
    }
    if (this.selectedGender != undefined && this.selectedGender != '')
    {
      payload['gender'] = this.selectedGender;
    }
    const updateLanguage = this.profileApi.updateBloodGroup(payload).subscribe(
      {
        next: (res) => {
          // console.log(res.data);
          this.requestInProgress = false;
          this.auth.setUserInfo(res.data.user);
          if (this.languageResultCheck)
          {
            this.authState.setTabStatus('ADDRESS_INITIAL');
            this.navigateTab.emit('ADDRESS');
          }
        },
        error: (error) => {
          // console.log(error);
          this.requestInProgress = false;
          let errors;
          if (error.data != undefined)
          {
            errors = error.data.errors;
          }
          if (errors.constructor === Object)
          {
            errors = error.message;
          }
          this.toast.error(errors);
        },
      }
    )
    this.setSubscription(updateLanguage);
  }

  deleteConfirmation(index) {
    let contact = this.languageArray.controls[index]?.value;
    contact['index'] = index;
    this.dialog.open(this.deleteDialog, {
      height: '220px',
      width: '450px',
      disableClose: true,
      data: {
        languageInfo: contact
      }
    })
  }
  closeDeleteConfirmation(langugaeInfo) {
    if(langugaeInfo){
      // this.languageArray.removeAt(langugaeInfo.index);
      let removevalue = this.languageArray.controls[langugaeInfo.index]?.value;
      // console.log(removevalue,langugaeInfo);return;
      const i = this.values.indexOf(removevalue.languageCode);
      if (i !== -1) {
        this.values.splice(i, 1);
      }
      this.languageArray.removeAt(langugaeInfo.index);
      if (langugaeInfo.contact_id) {
        this.deleteLanguage(langugaeInfo);
      }
    }

  }

  deleteLanguage(languageInfo){
    let languageId = languageInfo.contact_id;
    this.requestInProgress = true;
    const updateNetwork = this.profileApi.deleteLanguage(languageId).subscribe(
      {
        next: (res) => {
          // console.log(res.data);
          this.requestInProgress = false;
          this.toast.success(res.message);
        },
        error: (error) => {
          // console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(updateNetwork);
  }


  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

<section class="image-cropper" [ngClass]="{'active': !shape }" fxLayout.xs="column" fxLayout.sm="column">
    <div fxLayout="column" fxFlex="45" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="space-around center start">
        <label>Cropping Area</label>
        <div class="cropper">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="ratio" [resizeToWidth]="width" [cropperMinWidth]="128" [onlyScaleDown]="false" [roundCropper]="shape"
                [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>
    </div>
    <div fxLayout="column" fxFlex="5" fxHide.xs fxHide.sm fxLayoutAlign="space-around center center" class="center-border"></div>
    <div fxLayout="column" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="space-around center end">
        <label>Cropping Preview</label>
        <div class="cropped_image" [ngClass]="{'active': shape }">
            <img [src]="croppedImage" [ngClass]="{'active': shape }">
        </div>
        <div fxLayout="row" class="footer">
            <button class="active cp" (click)="crop()">Crop</button>
            <button class="active cp" (click)="close()">Cancel</button>
        </div>
    </div>
</section>
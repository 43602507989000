export enum ReferenceName {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  USER_INFO = 'user_info',
  KYC='kyc_info',
  PROFILE_STATUS='profile_status',
  PROFILE_NAV_STATUS='profile_nav_status',
  IS_PASSWORD_EXPIRED='is_password_expired',
  FORGOT_USERNAME='forgot_username',
  FORGOT_PASSWORD='forgot_password',
  FORGOT_PASSWORD_HINT='forgot_password_hint',
  PRE_REGISTER_TOKEN = 'register_token',
  CORPORATE_REGISTRATION_STEP = 'corporate_registration_step',
  PAN_INFO = 'pan_info',
  GST_INFO = 'gst_info',
  PRE_REGISTER_INFO = 'pre_register_info'
}

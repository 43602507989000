import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProfileApiService } from 'src/app/core/services/api/profile-api/profile-api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AuthDataService } from 'src/app/core/services/data/auth-data/auth-data.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { FORM_VALIDATION } from 'src/app/data/form-validation-config';

@Component({
  selector: 'yosync-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.scss']
})
export class UserAddressComponent implements OnInit {

  @Input() userProfile: any;
  @Input() showTab: any;
  //@Input() availableLabel: any;
  @Output() navigateTab: EventEmitter<any> = new EventEmitter();
  @ViewChild('deleteDialog') deleteDialog: TemplateRef<any>;
  subscriptions: Subscription[] = [];
  addressForm!: FormGroup;
  formValidation = FORM_VALIDATION;
  requestInProgress: boolean = false;
  primaryAddress: any;
  labelName = [];
  addedLabels: any;
  availableLabel: any;
  objectKeys = Object.keys;

  constructor(
    private fb: FormBuilder,
    private toast: AppToastrService,
    private profileApi: ProfileApiService,
    private auth: AuthService,
    public dialog: MatDialog,
    private authState: AuthDataService
  ) { }

  ngOnInit(): void {
    const availableLabels = this.authState.select('availableLabels').subscribe(res => {
      this.availableLabel = res;
      //console.log(this.availableLabel);
    });
    this.setSubscription(availableLabels);
    this.availableLabel = this.availableLabel.filter(x => (x.label_code !== 'MOBILE' && x.label_code !== 'OTHER') || (x.label_code === 'OTHER' && x.is_system === false));
    //console.log(this.availableLabel);
    this.addressForm = this.fb.group({
      userAddress: this.fb.array([]),
    });
    const userInfo = this.authState.select('userInfo').subscribe(res => {
      this.userProfile = res;
      this.patchAddress();
    });
    this.setSubscription(userInfo);
    this.primaryAddress = this.userProfile.address.filter(y => y.is_permanent_address);

    this.addressForm.valueChanges.subscribe(() => {
      if (this.addressForm.invalid)
      {
        this.authState.setTabStatus('ADDRESS_INCOMPLETE');
      }
      else
      {
        this.authState.setTabStatus('ADDRESS_COMPLETED');
      }
    });
  }

  patchAddress() {
    let otherAddress = this.userProfile.address.filter(x => !x.is_permanent_address);
    if (otherAddress.length > 0) {
      this.addressForm = this.fb.group({
        userAddress: this.fb.array([]),
      });
      otherAddress.forEach(e => {
        let addressExist = this.fb.group({
          type: e.type.code,
          label: e.label.code,
          name_of_label: e.label.name,
          name: e.name,
          address_1: e.address_1,
          address_2: e.address_2,
          city: e.city,
          pincode: e.pincode,
          state: e.state,
          country: e.country,
          is_primary: e.is_primary,
          address_id: e.id,
          label_name: ''
        });
        this.addressArray.push(addressExist);
      });
    }

  }

  selectLabel(labelCode, labelName, index) {
    if (labelName != '' && labelName != undefined)
    {
      this.addressArray.controls[index].get('label').setValue(labelCode);
      this.addressArray.controls[index].get('name_of_label').setValue(labelName);
      if (labelCode == 'OTHER')
      {
        let obj = {
          id: '',
          is_system: 0,
          label_code: labelCode,
          label_name: labelName,
          status: "ACTIVE",
          user_id: ""
        }
        this.addedLabels = obj;
        //console.log(this.addedLabels);
      }
    }
  }

  get f() {
    return this.addressForm.controls.userAddress;
  }

  getAddress(address) {
    let newAddress = address.address_1 + ', ' + address.address_2 + ', ' + address.area + ', ' + address.city + ', ' + address.state + ', ' + address.country + ', ' + address.pincode
    return newAddress;
  }

  addAddress() {
    const results = this.availableLabel.filter(({ label_code: id1 }) => !this.addressForm.value.userAddress.some(({ label: id2 }) => id2 === id1));
    let labelCode, labelName;
    if(results.length > 0){
       labelCode = results[0].label_code;
       labelName = results[0].label_name;
    } else {
      const index = Math.floor(this.availableLabel.length * Math.random());
      const pickAnyLabel = this.availableLabel[index];
      labelCode = pickAnyLabel.label_code;
      labelName = pickAnyLabel.label_name;
    }
    if (this.addedLabels != undefined) 
    {
      this.availableLabel.push(this.addedLabels);
      this.addedLabels = undefined;
      this.authState.setAvailableLabels(this.availableLabel);
      //console.log(this.authState.getAvailableLabels());
    }
    
    return this.fb.group({
      type: ['ADDRESS'],
      label: [labelCode, Validators.required],
      name_of_label: [labelName, Validators.required],
      name: ['', Validators.required],
      address_1: ['', Validators.required],
      address_2: [''],
      city: ['', Validators.required],
      pincode: ['', Validators.required],
      state: ['', Validators.required],
      country: ['India', Validators.required],
      is_primary: false,
      label_name: '',
    });
  }

  addMoreAddress() {
    this.addressArray.push(this.addAddress());
  }

  removeAddress(index) {
    if (index > -1) {
      // this.addressArray.removeAt(index);
      this.deleteConfirmation(index)
    }
  }

  get addressArray() {
    return (this.addressForm.get('userAddress') as FormArray);
  }

  createAddress() {
    if(this.addressForm.value.userAddress.length==0){
      this.authState.setTabStatus('NETWORK_INITIAL');
      this.navigateTab.emit('NETWORK');
      return;
    }
    if (this.addressForm.invalid) {
      this.requestInProgress = false;
      this.addressForm.markAllAsTouched();
      return;
    }
    let payload = {
      "address": this.addressForm.value.userAddress
    }
    this.requestInProgress = true;
    const updateLanguage = this.profileApi.addAddress(payload).subscribe(
      {
        next: (res) => {
          // console.log(res.data);
          this.requestInProgress = false;
          this.auth.setUserInfo(res.data.user);
          this.authState.setTabStatus('NETWORK_INITIAL');
          this.navigateTab.emit('NETWORK');
        },
        error: (error) => {
          // console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(updateLanguage);
  }

  updateAddress() {
    let payload = {
      "address": this.addressForm.value.userAddress
    }
    this.requestInProgress = true;
    const updateLanguage = this.profileApi.addAddress(payload).subscribe(
      {
        next: (res) => {
          // console.log(res.data);
          this.requestInProgress = false;
          this.auth.setUserInfo(res.data.user);
          this.navigateTab.emit('NETWORK');
        },
        error: (error) => {
          // console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(updateLanguage);
  }

  deleteConfirmation(index) {
    let contact = this.addressArray.controls[index]?.value;
    contact['index'] = index;
    this.dialog.open(this.deleteDialog, {
      height: '220px',
      width: '450px',
      disableClose: true,
      data: {
        addressInfo: contact
      }
    })
  }
  closeDeleteConfirmation(addressInfo) {
    if (addressInfo) {
      this.addressArray.removeAt(addressInfo.index);
      if (addressInfo.address_id) {
        this.deleteAddress(addressInfo);
      }
    }

  }

  deleteAddress(addressDetail) {
    let addressId = addressDetail.address_id;
    this.requestInProgress = true;
    const updateNetwork = this.profileApi.deleteAddress(addressId).subscribe(
      {
        next: (res) => {
          // console.log(res.data);
          this.requestInProgress = false;
          this.toast.success(res.message);
          let userData = this.authState.getUserProfileInfo();
          for (let i = 0; i < userData.address.length; i++)
          {
            if (userData.address[i].id == addressDetail.address_id)
            {
              userData.address.splice(i, 1);
            }
          }
          this.auth.setUserInfo(userData);
        },
        error: (error) => {
          // console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(updateNetwork);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}

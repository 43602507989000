<div slot="modal-body" class="content-body">
    <span fxLayout="column" fxLayoutAlign="center end" class="close-button cp" (click)="close()">
    <mat-icon>close</mat-icon>
  </span>
    <div class="banner mt-2 pa-2">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div fxFlex="auto" fxLayoutAlign="center center" class="profile">
                <img [src]="profileImgUrl" class="profile-img" />
            </div>
        </div>
        <br />
        <div fxLayout="column" fxLayoutAlign="center center" class="image-desc">
            <div class="imagesize">Optimal Image Size</div><br>
            <div class="size">1280 x 256 px</div><br>
            <div class="yosync-login-footer " fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button class="button  reset cp" *ngIf="uploadInProgress" (click)="browse()"><a>{{!profileImgUrl?"Choose":"Edit"}}</a>
        </button>
                <button class="button  back cp" *ngIf="uploadInProgress" (click)="close()"><a>Cancel</a>
        </button>
                <input hidden #profileFile type="file" (change)="onSelectFile($event)" accept="image/*" style="padding: 100px;" multiple>
            </div>
        </div>
        <yosync-image-cropper *ngIf="cropAttributes" [cropAttributes]="cropAttributes" (cropImage)="preparePayload($event)">
        </yosync-image-cropper>
    </div>
    <!-- <yosync-spinner *ngIf="profileUploadInProgress" [config]="{fullscreen: true}"></-spinner> -->
</div>
<!-- </dh-router-modal> -->
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'yosync-password-expired-alert',
  templateUrl: './password-expired-alert.component.html',
  styleUrls: ['./password-expired-alert.component.scss']
})
export class PasswordExpiredAlertComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  redirect()
  {
    this.router.navigate(['/change-password']);
  }

}

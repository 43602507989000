<!-- <div class="yosync-loader">
  <div [ngClass]="{'overlay': true}">
    <div class="bg-overlay"></div>
    <div class="nest"></div>
    <div class="loading"></div>
    <p class="spinner-message" *ngIf="text && text !== ''">
      {{text}}
    </p>
  </div>
</div> -->

<div class="yosync-loader">
  <div [ngClass]="{'overlay': true}">
    <div class="bg-overlay"></div>
    <span class="loader"></span>
  </div>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProfileApiService } from 'src/app/core/services/api/profile-api/profile-api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ImageValidateService } from 'src/app/core/services/image-validate/image-validate.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';

@Component({
  selector: 'yosync-user-profile-image-editor',
  templateUrl: './user-profile-image-editor.component.html',
  styleUrls: ['./user-profile-image-editor.component.scss']
})
export class UserProfileImageEditorComponent implements OnInit {

  @ViewChild('profileFile', { static: true }) file: ElementRef;
  private subscriptions: Subscription[] = [];
  selectedFile: any;
  url: any;
  userInfo: any;
  profileImgUrl: any;
  profileUploadInProgress: boolean = false;
  fileName: string;
  cropAttributes: any;
  requestInProgress: boolean = false;
  uploadInProgress: boolean = true;

  constructor(
    private toast: AppToastrService,
    private imageValidate: ImageValidateService,
    private dialogRef: MatDialogRef<UserProfileImageEditorComponent>,
    private authService: AuthService,
    public dialog: MatDialog,
    private profileApi: ProfileApiService,
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.profileImgUrl = this.userInfo.profile_image.preview_url;
  }

  browse() {
    this.file.nativeElement.click();
  }

  onSelectFile(event) {
    const validate = this.imageValidate.validateImage(event.target);
    if (validate) {
      this.toast.error('Only files with following extensions are allowed: ' + this.imageValidate.extensions.join(','));
      return;
    }

    this.cropAttributes = {
      file: event,
      shape: 'round',
      ratio: 1 / 1,
      type: 'profile',
      width: 328,
    };
  }

  preparePayload(event) {
    console.log(event);
    let payload = {
      'base64_data': event.split('base64,')[1],
      'type': 'PROFILE_IMAGE',
      'file_type': 'BASE64',
    }
    const updateLanguage = this.profileApi.uploadAsset(payload).subscribe(
      {
        next: (res) => {
          console.log(res.data);
          this.requestInProgress = false;
          this.profileUpdate(res.data.asset);
        },
        error: (error) => {
          console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(updateLanguage);
  }

  profileUpdate(profileImgae) {
    let payload = {
      "profile_image": profileImgae.id
    }
    const updateLanguage = this.profileApi.updateProfileImage(payload).subscribe(
      {
        next: (res) => {
          console.log(res.data);
          this.requestInProgress = false;
          this.authService.setUserInfo(res.data.user);
          this.close();
        },
        error: (error) => {
          console.log(error);
          this.requestInProgress = false;
          this.toast.error(error.message);
        },
      }
    )
    this.setSubscription(updateLanguage);
  }

  close() {
    this.dialogRef.close();
  }

  setSubscription(request) {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}

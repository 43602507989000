import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'yosync-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit {

  @Input() passwordString: string = '';
  @Output() passwordStrength = new EventEmitter<string>();
  // colors = ['darkred', 'orangered', 'orange', '#75E87D', '#75E87D'];
  colors = ['#75E87D', '#75E87D', '#75E87D', '#75E87D', '#75E87D'];
  msg: string;
  active0: string;
  active1: string;
  active2: string;
  active3: string;
  active4: string;

  constructor() { }

  ngOnInit(): void {
    console.log(this.passwordString);
  }

  checkStrength(password) {
    let force = 0;

    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(password);
    const upperLetters = /[A-Z]+/.test(password);
    const numbers = /[0-9]+/.test(password);
    const symbols = regex.test(password);
    const length = /^.{12,35}$/.test(password);

    const flags = [lowerLetters, numbers, upperLetters, symbols, length];

    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    force += 2 * password.length + ((password.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;
    // force = (password.length <= 8) ? Math.min(force, 40) : force;
    force = (password.length > 12) ? Math.min(force, 50) : force;
    // console.log(force);

    return force;
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const password = changes.passwordString.currentValue;
    this.setCircleColor(5, '#B1B1B1');
    if (password) {
      const c = this.getColor(this.checkStrength(password));
      this.setCircleColor(c.index, c.color);

      const pwdStrength = this.checkStrength(password);
      // pwdStrength >= 12 ? this.passwordStrength.emit(true) : this.passwordStrength.emit(false);

      switch (c.index) {
        case 1:
          this.msg = 'Poor';
          break;
        case 2:
          this.msg = 'Not Good';
          break;
        case 3:
          this.msg = 'Average';
          break;
        case 4:
          this.msg = 'Good';
          break;
        case 5:
          this.msg = 'Strong';
          break;
      }
    } else {
      this.msg = '';
    }
    this.passwordStrength.emit(this.msg);
  }

  setCircleColor(count, col) {
    for (let n = 0; n < count; n++) {
      this['active' + n] = col;
    }
  }

  getColor(s) {
    let index = 0;
    if (s === 10) {
      index = 0;
    } else if (s === 20) {
      index = 1;
    } else if (s === 30) {
      index = 2;
    } else if (s === 40) {
      index = 3;
    } else {
      index = 4;
    }
    return {
      index: index + 1,
      color: this.colors[index]
    };
  }

}

import { HttpClientService } from './../../http-client/http-client.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private httpService: HttpClientService) { }

  login(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/login', payload);
  }

  clientLogin(payload: any): Observable<any>{
    return this.httpService.post(environment.BASE_URL + '/client/login', payload);
  }

  refreshToken(payload: { refresh_token: any; }): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/refresh-token', payload);
  }

  logout(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/logout', "");
  }

  sendMobileOtp(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/register/phone/send-otp', payload);
  }

  sendEmailOtp(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/register/email/send-verification', payload);
  }

  verifyMobileOtp(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/register/phone/verify', payload);
  }

  verifyEmailOtp(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/register/email/verify', payload);
  }

  initiatePreregister(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/register/initiate', payload);
  }

  forgotPassword(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/forgot-password', payload);
  }

  forgotPasswordVerifyEmail(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/forgot-password/verify/email', payload);
  }

  forgotPasswordVerifyHint(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/verify/password-hints', payload);
  }

  forgotPasswordHintForgot(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/forgot-password/hint/forgot', payload);
  }


  forgotPasswordSendOtp(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/forgot-password/email/send-verification', payload);
  }

  forgotPasswordEmailVerify(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/forgot-password/email/verify', payload);
  }
  
  forgotUserIdSendOtp(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/forgot-user-id', payload);
  }

  forgotUserIdVerify(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/forgot-user-verify', payload);
  }

  resetPassword(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/reset-password', payload);
  }

  changePassword(payload: any) {
    return this.httpService.post(environment.BASE_URL + '/change-password', payload);
  }

  getProduct(): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/product/list', { headers: { 'content-type': 'application/json' } });
  }

  initiateAadhar(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/kyc/aadhar/initiate', payload);
  }

  aadharStatus(token): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/kyc/aadhar/status/' + token, null);
  }

  getPasswordhinds(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/register/password/hints', null);
  }

  completeRegistration(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/register/complete', payload);
  }

  checkUserName(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/register/username/check', payload);
  }

  getUserProfile(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/user/profile/view', null);
  }

  getAadharInfo(id: string): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/kyc/aadhar/view/' + id, null);
  }

  confirmAadharInfo(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/kyc/aadhar/confirm/', payload);
  }


}
